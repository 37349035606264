import React, {useState, useEffect} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import MaterialTable,{MTableHeader, MTableToolbar} from 'material-table';
//query
import AssocUserTypeIndicteurAPI from '../services/AssocUserTypeIndicateurApi';
import userApi from '../services/UserApi';
import serviceApi from '../services/ServiceApi';
import rightApi from '../services/rightApi';
import jwtDecode from "jwt-decode";
//icons
import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import RemoveIcon from '@material-ui/icons/Remove';
//material-ui
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';
//checkbox 
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
//styling
import * as styling from '../services/styling';

const useStyles = makeStyles(theme => ({
    root: {
        root: {
            //marginTop: '15vh',
            marginLeft: '30px',
            width: 'fit-content',
            //remplace with breakpoint
        },
    },
    page: {
        marginLeft: '30px',
        marginRight: '30px',
    },
    table: {
        //marginLeft: '30px',
        //marginRight: '30px',
        maxWidth:"100%",

    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    toolbar:{
        backgroundColor: styling.backGroundHeader,
        
    },
}));

const RightsManagement = () => {
    //styling
    const classes = useStyles();
    //isLoading
    const [chargement, setChargement] = useState({
        isLoading: false
    });

    //data entries
    const [entries, setEntries] = useState([]);
    const [saveEntries, setSaveEntries] = useState(new Map());
    const [refreshTab, setRefreshTab] = useState();

    //onchange input select  catégorie / sousCategorie

    const [selectsValues, setSelectsValues] = useState( new Map() );

    //user
    const [userSelect, setUserSelect] = useState([]);
 
    //selects utilisateur
    const ListOptions = {};
    let userList = [];
    let decodedToken = "";

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                //get role
                const tokenBrut = window.localStorage.getItem("authToken");
                if(tokenBrut){
                    decodedToken = jwtDecode(tokenBrut);

                }
                const userData = await userApi.findAllUsers(); //query
                if (decodedToken.roles[0] === "SUPER_ADMIN") {
                    userData.forEach(element => {
                        userList.push({
                            id: element.id,
                            email: element.email,
                        });
                    });
                } else {
                    userData.forEach(element => {
                        if (element.roles[0] === "USER") {
                            userList.push({
                                id: element.id,
                                email: element.email,
                            });
                        }
                        if (element.roles[0] === "SUPER_USER") {
                            userList.push({
                                id: element.id,
                                email: element.email,
                            });
                        }
                    });
                }
                userList.map(user => {
                    const { id, email } = user;
                    ListOptions[email] = email;
                })
                setUserSelect(userList)
            }
            catch (err) {
                console.log(err)
            }
        };
        fetchUsers();
    }, []);

    //checkbox column values

    const checkAll = (data) => {

        let countLecture = 0;
        let countEcriture = 0;
        let countSuppression = 0;
        data.forEach(element => {
            if (element.assocUserTypeIndicateurs.lecture) {
                countLecture++;
            }
            if(element.assocUserTypeIndicateurs.ecriture){
                countEcriture++;
            }
            if(element.assocUserTypeIndicateurs.suppression){
                countSuppression++
            }
        })

        data.length === countLecture ?setLectureAllChecked(prevState => prevState.set("lecture", true)) : setLectureAllChecked(prevState => prevState.set("lecture", false));
        data.length === countEcriture ? setEcritureAllChecked((prevState => prevState.set("ecriture", true))) : setEcritureAllChecked((prevState => prevState.set("ecriture", false)));
        data.length === countSuppression ? setSuppressionAllChecked((prevState => prevState.set("suppression", true))) : setSuppressionAllChecked((prevState => prevState.set("suppression", false)));


        //set the columns
        setState({
            columns: [
                {
                    title: 'Utilisateurs', field: 'assocUserTypeIndicateurs.email',editable:'never',
                    headerStyle: {
                        textTransform: "uppercase",
                        textAlign: 'left',
                    },
                },
                {
                    title: 'Type indicateurs', field: 'libelle',editable:'never', 
                    headerStyle: {
                        textTransform: "uppercase",
                        textAlign: 'left',
                    },
                },
                {
                    title: 
                    <Box >
                        <Box component="div" display="inline">Lecture</Box>
                        <Checkbox
                            display="inline"
                            checked={lectureAllChecked.get("lecture")|| false}
                            onChange={handleChangeLectureAll}
                            
                        />
                    </Box>, 
                    field: 'assocUserTypeIndicateurs.lecture', type: "boolean", sorting: false, tooltip:"Tout cocher",
                    headerStyle: {
                        textTransform: "uppercase",
                        
                    }, 
                    cellStyle: {
                        textAlign: 'center',
                        
                    },
                    render: rowData => <Checkbox
                    checked={rowData.assocUserTypeIndicateurs.lecture ? rowData.assocUserTypeIndicateurs.lecture : false}
                    onChange={(event) => handleChangeLecture(event, rowData )}              
                    />
                },
                {
                    title: 
                        <Box >
                            <Box component="div" display="inline">Ecriture</Box>
                            <Checkbox
                                display="inline"
                                checked={ecritureAllChecked.get("ecriture") || false}
                                onChange={handleChangeEcritureAll}
                            />
                        </Box>,
                    field: 'assocUserTypeIndicateurs.ecriture', type: "boolean", sorting: false,tooltip:"Tout cocher",
                    headerStyle: {
                        textTransform: "uppercase",
                        
                    }, 
                    cellStyle: {
                        textAlign: 'center',
                        
                    },
                    render: rowData => <Checkbox
                    checked={ rowData.assocUserTypeIndicateurs.ecriture ? rowData.assocUserTypeIndicateurs.ecriture : false }
                    onChange={(event) =>handleChangeEcriture(event, rowData )}                
                    />
                },
                {
                    title: 
                        <Box >
                            <Box component="div" display="inline">Suppression</Box>
                            <Checkbox
                                display="inline"
                                checked={suppressionAllChecked.get("suppression") || false }
                                onChange={handleChangeSuppressionAll}
                            />
                        </Box>,                
                    field: 'assocUserTypeIndicateurs.suppression', type: "boolean", sorting: false, tooltip:"Tout cocher",
                    headerStyle: {
                        textTransform: "uppercase",
                        
                    }, 
                    cellStyle: {
                        textAlign: 'center',
                        
                    },
                    render: rowData => <Checkbox
                    checked={rowData.assocUserTypeIndicateurs.suppression ? rowData.assocUserTypeIndicateurs.suppression : false}
                    onChange={(event) =>handleChangeSuppression(event, rowData )}                
                    />
                },
                {
                    title: 'Thème', field: 'idSousCategorie.idCategorie.libelleCategorie',editable:'never', 
                    headerStyle: {
                        textTransform: "uppercase",
                        textAlign: 'left',
                    },
                },
                {
                    title: 'Sous-thème', field: 'idSousCategorie.libelleSousCategorie',editable:'never', 
                    headerStyle: {
                        textTransform: "uppercase",
                        textAlign: 'left',
                    },
                },            
            ],
        })
        setEntries(data); 
    }

    // sort entries by catégorie, sous catégorie and responsable
    const sortEntries = async (param) => {
        const selectsValues = {
            selectCategorie: param.get("selectCategorie") ? param.get("selectCategorie") : null,
            selectCategorieName: param.get("selectCategorieName") ? param.get("selectCategorieName") : null,
            selectSousCategorie: param.get("selectSousCategorie") ? param.get("selectSousCategorie") : null,
            selectSousCategorieName: param.get("selectSousCategorieName") ? param.get("selectSousCategorieName") : null,
            selectResponsable: param.get("selectResponsable") ? param.get("selectResponsable") : null,
            selectResponsableName: param.get("selectResponsableName") ? param.get("selectResponsableName") : null,
            userSelect : param.get("userSelect") ? param.get("userSelect") : null
        };
        
        try{
            //animation
            setChargement({
                isLoading: true
            });
            const filtredTypeIndicateur = await serviceApi.sortTypeIndicateurs(selectsValues);
            const responseAssocUserTypeIndicateur = await AssocUserTypeIndicteurAPI.findAllAssocUserTypeIndicteur();

            let allAssocUser = [];
            responseAssocUserTypeIndicateur.forEach(element => {                
                 let id = parseInt(element.iduser.match(/\/(\d+)+[\/]?/g).map(id => id.replace(/\//g, '')));       
                if (parseInt(selectsValues.userSelect.value) === id) {
                    allAssocUser.push({
                        id: element.id,
                        iduser: parseInt(selectsValues.userSelect.value),
                        email: selectsValues.userSelect.name,
                        idtypeindicateur: element.idtypeindicateur,
                        roles: element.roles,
                        lecture: element.lecture,
                        ecriture: element.ecriture,
                        suppression: element.suppression
                    })
                }      

            })
            //create the new table 
            //add empty assocUserTypeIndicateurs
            filtredTypeIndicateur.forEach(element => {
                element.assocUserTypeIndicateurs =
                {
                    id: null,
                    iduser: selectsValues.userSelect.value,
                    email: selectsValues.userSelect.name,
                    idtypeindicateur: { id: element.id },
                    roles: null,
                    lecture: null,
                    ecriture: null,
                    suppression: null
                }
            })
            //replace the assocUserTypeIndicateurs 
            filtredTypeIndicateur.forEach(element => {
                allAssocUser.forEach(el => {
                    const userVal = parseInt((selectsValues.userSelect.value))
                    if (userVal === el.iduser) {
                        if (element.id === el.idtypeindicateur.id) {
                            element.assocUserTypeIndicateurs = el
                        }
                    }
                })
            })     
            
            setSaveEntries(prevState => prevState.set("saveEntries", filtredTypeIndicateur));
            checkAll(filtredTypeIndicateur)
            //setEntries(filtredTypeIndicateur);
            
            //animation
            setChargement({
                isLoading: false
            });
        }catch(error){
            console.log(error)
        }
    }

    const sortSelectSousCategorie = async(idCategorie) => {
        try {
            const dataNewSousCategorie = await serviceApi.sortNewSousCategorie(idCategorie);
            let sortSousCategorieList = [];
            dataNewSousCategorie.forEach(element => {
                sortSousCategorieList.push({
                    value: element.id,
                    name: element.libelleSousCategorie,
                    id_categorie: element.idCategorie,
                    id: element.id
                });
            });
            setSousCategorie(sortSousCategorieList)

        } catch (error) {
            console.log(error);
        }
    };

    // select visibility
    const [selectVisibility, setSelectVisibility] = useState('hidden');
    // on change User select    
    const handleChangeResponsable = event => {
        event.preventDefault();
        const dataValue = event.currentTarget.getAttribute("data-value");
        const dataName = event.currentTarget.getAttribute("name");
        const userObj = {
            name: dataName,
            value: dataValue
        }

        setSelectsValues(prevState => prevState.set("userSelect", userObj))

        //visibility
        setSelectVisibility('visible');

        //sorting
        sortEntries(selectsValues);

    }


    //data categorie
    const [categorie, setCategorie] = useState([]);    
    useEffect(() => {
        const fetchCategorie = async () => {
            try {
                const dataCategorie = await serviceApi.findAllCategorie();
                let CategorieList = [];
                dataCategorie.forEach(element => {
                    CategorieList.push({
                        value: element.id,
                        name: element.libelleCategorie,
                        id: element.id
                    });
                });
                setCategorie(CategorieList)
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchCategorie();
    }, []);
    
    // on change categorie
    const handleChangeCategorie = (event) => {
        event.preventDefault();
        const dataValue = event.currentTarget.getAttribute("data-value");
        const dataName = event.currentTarget.getAttribute("name");

        sortSelectSousCategorie(dataValue);

        setSelectsValues(prevState => prevState.set("selectCategorie", dataValue))
        setSelectsValues(prevState => prevState.set("selectCategorieName", dataName))

        // sorting
        sortEntries(selectsValues);
    };

    //data sous categorie
    const [sousCategorie, setSousCategorie] = useState([]);
    useEffect(() => {
        const fetchSousCategorie = async () => {
            try {
                const dataSousCategorie = await serviceApi.findAllSousCategorie();
                let SousCategorieList = [];
                dataSousCategorie.forEach(element => {
                    SousCategorieList.push({
                        value: element.id,
                        name: element.libelleSousCategorie,
                        id_categorie: element.idCategorie,
                        id: element.id
                    });
                });
                setSousCategorie(SousCategorieList)
            }
            catch (err) {
                console.log(err)
            }
        };
        fetchSousCategorie();
    }, []);
    
    //on change sousCategorie
    const handleChangeSousCategorie = (event) => {
        const dataValue = event.currentTarget.getAttribute("data-value");
        const dataName = event.currentTarget.getAttribute("name");

        setSelectsValues(prevState => prevState.set("selectSousCategorie", dataValue))
        setSelectsValues(prevState => prevState.set("selectSousCategorieName", dataName))
        sortEntries(selectsValues);

    };




    //checkboxes

    const [lectureAllChecked,setLectureAllChecked] = useState( new Map() );
    const [ecritureAllChecked,setEcritureAllChecked] = useState( new Map() );
    const [suppressionAllChecked,setSuppressionAllChecked] = useState( new Map() );

    const handleChangeLectureAll = async (event) => {        
        event.stopPropagation()
        const data = saveEntries.get("saveEntries")
        try {
            //animation
            setChargement({
                isLoading: true
            });
            
            await rightApi.toggleLectureAll(data, event.target.checked);

            setChargement({
                isLoading: false
            });

            //reload         
            sortEntries(selectsValues);

        }catch(err) {
            console.log(err);
        }
        
    };
    const handleChangeEcritureAll = async (event) => {       
        event.stopPropagation()
        const data = saveEntries.get("saveEntries")
        try {
            //animation
            setChargement({
                isLoading: true
            });
            
            await rightApi.toggleEcritureAll(data, event.target.checked);

            setChargement({
                isLoading: false
            });

            //reload         
            sortEntries(selectsValues);

        }catch(err) {
            console.log(err);
        }
    };
    const handleChangeSuppressionAll = async (event) => {        
        event.stopPropagation()
        const data = saveEntries.get("saveEntries")
        try {
            //animation
            setChargement({
                isLoading: true
            });
            
            await rightApi.toggleSuppressionAll(data, event.target.checked);

            setChargement({
                isLoading: false
            });

            //reload         
            sortEntries(selectsValues);

        }catch(err) {
            console.log(err);
        }
    };
    // by cell
    const handleChangeLecture = async (event, rowdata) => {
        event.stopPropagation()
        try {
            //animation
            setChargement({
                isLoading: true
            });

            await rightApi.toggleLecture(rowdata, event.target.checked);

            setChargement({
                isLoading: false
            });

            //reload         
            sortEntries(selectsValues);

        }catch(err) {
            console.log(err)
        }
    };

    const handleChangeEcriture = async (event, rowdata) => {        
        event.stopPropagation()
        try {
            //animation
            setChargement({
                isLoading: true
            });

            const response = await rightApi.toggleEcriture(rowdata, event.target.checked);

            setChargement({
                isLoading: false
            });

            //reload          
            sortEntries(selectsValues);
            
        }catch(err) {
            console.log(err)
        }
    };

    const handleChangeSuppression = async (event, rowdata) => {      
        event.stopPropagation()
        try {
            //animation
            setChargement({
                isLoading: true
            });

            const response = await rightApi.toggleSuppression(rowdata, event.target.checked);

            setChargement({
                isLoading: false
            });

            //reload        
            sortEntries(selectsValues);

        }catch(err) {
            console.log(err)
        }
    };

    const handleRowClick = (event, rowData) => {
        //console.log(rowData)
    }
    //columns definition
    const [state, setState] = useState({

    });



    return (
        <div className={classes.page}>
            <Grid container alignItems="center" className={classes.root}>
            <FormControl className={classes.formControl}>                
                    <InputLabel id="user-simple-select-autowidth-label">Utilisateurs</InputLabel>
                    <Select
                        labelId="select-users-required-label"
                        id="select-users-required"
                        value={userSelect.id}
                        defaultValue={userSelect.id ? userSelect.id : ""}
                        autoWidth
                        onChange={handleChangeResponsable}
                        className={classes.selectEmpty}
                    >
                        {userSelect.map((user,index) => (
                            <MenuItem key={index} value={user.id} name={user.email}>{user.email}</MenuItem>
                        ))}
                    </Select>
                </FormControl>                
                <Divider
                    orientation="vertical"
                    flexItem
                    light
                />
                <FormControl className={classes.formControl}>
                <InputLabel id="categorie-simple-select-autowidth-label" style={{visibility: selectVisibility}} >Thème</InputLabel>
                    <Select
                        labelId="categorie-simple-select-autowidth-label"
                        id="categorie-simple-select-autowidth"
                        value={categorie.value}
                        defaultValue="_all"
                        onChange={handleChangeCategorie}
                        autoWidth
                        style={{visibility: selectVisibility}}
                    >
                        <MenuItem value="_all" ><em>Toutes</em></MenuItem>
                        {categorie.map((cat) => (
                            <MenuItem key={cat.id} value={cat.value} name={cat.name}>{cat.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>  
                <FormControl className={classes.formControl}>
                    <InputLabel id="ssCategorie-simple-select-autowidth-label" style={{visibility: selectVisibility}}>Sous-thème </InputLabel>
                    <Select
                        labelId="ssCategorie-simple-select-autowidth-label"
                        id="ssCategorie-simple-select-autowidth"
                        value={sousCategorie.value}
                        defaultValue="_all"
                        onChange={handleChangeSousCategorie}
                        autoWidth
                        style={{visibility: selectVisibility}}
                    >
                        <MenuItem value="_all"><em>Toutes</em></MenuItem>
                        {sousCategorie.map((sscat) => (
                            <MenuItem key={sscat.id} value={sscat.value} name={sscat.name}>{sscat.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <div className={classes.table}>
                <MaterialTable
                    title={'Gestion des droits d\'accès utilisateurs/type indicateurs'.toUpperCase()}
                    columns={state.columns}
                    data={entries}
                    onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                    isLoading={chargement.isLoading}
                    components={{
                        Toolbar: props => (
                            <div style={{ backgroundColor: styling.backGroundHeader,color:styling.colorTextHeader  }}>
                                <MTableToolbar {...props} classes={{ root: classes.toolbar }} />
                            </div>
                        ),
                    }}                    
/*                     editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            if (oldData) {
                            setState((prevState) => {
                                const data = [...prevState.data];
                                data[data.indexOf(oldData)] = newData;
                                return { ...prevState, data };
                            });
                            }
                        }, 600);
                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            setState((prevState) => {
                            const data = [...prevState.data];
                            data.splice(data.indexOf(oldData), 1);
                            return { ...prevState, data };
                            });
                        }, 600);
                        }),
                    }} */
                    icons={{
                        Add: props => <AddCircleRounded {...props} style={{fill: "#2e7d32"}} />,
                        Edit: props => <Edit  {...props} style={{fill: "#175Be5"}} />,
                        Delete: props => <DeleteForeverIcon   {...props} style={{fill: "#d50000"}} />,
                        Check: props => <Check   {...props} style={{fill: "#2e7d32"}} />,
                        Clear: props => <Clear    {...props} style={{fill: "#d50000"}} />,
                        ThirdStateCheck: props => <RemoveIcon  {...props} style={{fill: "#d50000"}} />,
                    }}
                    options={{
                        
                        rowStyle: {
                            backgroundColor: styling.tableRowBackgroundColor,
                        },
                        headerStyle: {
                            backgroundColor: styling.tableHeaderBackgroundColor,
                            color: styling.tableHeaderColor,
                            textAlign: 'center',
                        },
                        paging: false, // disable pagination !
                        tableLayout: "fixed", //columns dimensions
                        //actionsColumnIndex: -1, //action column o right side
                        addRowPosition: 'first', //add row on top
                    }}
                    style = {{
                        color: styling.tableTextColor,
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: "{from} à {to} sur {count}",
                            labelRowsSelect: "résultats par page",
                            labelRowsPerPage: "résultats par page",
                            firstAriaLabel: "Première page",
                            firstTooltip: "Première page",
                            previousAriaLabel: "Page précédente",
                            previousTooltip: "Page précédente",
                            nextAriaLabel: "Page suivante",
                            nextTooltip: "Page suivante",
                            lastAriaLabel: "Dernière page",
                            lastTooltip: "Dernière page"
                        },
                        toolbar: {
                            addRemoveColumns: "Ajouter ou supprimer des colonnes",
                            nRowsSelected: "{0} ligne(s) sélectionnée(s)",
                            showColumnsTitle: "Afficher les colonnes",
                            showColumnsAriaLabel: "Afficher les colonnes",
                            exportTitle: "Exporter",
                            exportAriaLabel: "Exporter",
                            exportName: "Exporter en CSV",
                            searchTooltip: "Recherche",
                            searchPlaceholder: "Recherche"
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage:
                                "Désolé, aucun enregistrement correspondant n'a été trouvé",
                            addTooltip: "Ajouter une ligne",
                            deleteTooltip: "Supprimer l\'indicateur",
                            editTooltip: "Modifier l\'indicateur",
                            filterRow: {
                                filterTooltip: "Filtrer"
                            },
                            editRow: {
                                deleteText: "Êtes-vous sûr de vouloir supprimer cet élément ? Vous allez perdre TOUTES les données associées",
                                cancelTooltip: "Annuler",
                                saveTooltip: "Sauvegarder"
                            }
                        },
                    }}
                />  
            </div>      
        </div>


    )
}

export default RightsManagement;