// export const CONTROLLER_URL = "http://localhost:8000/";
// export const API_URL = "http://localhost:8000/api/";

// MAIN \\
export const CONTROLLER_URL = "//euraenergieback.espedata.fr/";
export const API_URL = "//euraenergieback.espedata.fr/api/";

export const TYPE_OBJECTIFS_API = API_URL + "type_objectifs";
export const ALL_OBJECTIFS = API_URL + "objectifs";
export const ONE_OBJECTIF = API_URL + "objectifs/";
export const TYPE_DATE_API = API_URL + "type_dates";
export const TYPE_UNITE_API = API_URL + "type_unites";
export const TYPE_INDICATEUR_API = API_URL + "type_indicateurs";
export const TYPE_CATEGORIE_API = API_URL + "categorie_indicateurs";
export const TYPE_SOUS_CATEGORIE_API = API_URL + "sous_categorie_indicateurs";
export const TYPE_CATEGORIE_ID_API = API_URL + "categorie_indicateurs/";
export const TYPE_OBJECTIFS_ID_API = API_URL + "type_objectifs/";
export const ALL_RESPONSABLE = API_URL + "responsables";
export const ASSOC_FLAG_INDICATEUR = API_URL + "assoc_flag_indicateurs";
export const ALL_TYPE_FLAG = API_URL + "type_flags";
export const ALL_FLAG_INDICATEUR_VALUES = API_URL + "flag_indicateur_values";
export const ALL_INDICATEURS = API_URL + "indicateurs";

export const USERS_API = API_URL + "users";
export const ASSOC_USER_TYPE_INDICATEUR = API_URL + "assoc_user_type_indicateurs";
export const LOGINCHECK_API = API_URL + "login_check";

export const SENDEMAIL = CONTROLLER_URL + "send_email";

export const GETSCHEMATABLE = CONTROLLER_URL + "schema_table";

export const SORTFLAG = API_URL + "sort_flag";
export const SORTASSOC = API_URL + "duplicate_flag";