import React, { useState, useEffect } from 'react';
//material-ui
import { makeStyles } from '@material-ui/core/styles';
//modal alert
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { TextField } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Save';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
//query
import serviceApi from '../services/ServiceApi';



//style
const useStyles = makeStyles(theme => ({
    formControl: {
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    container: {
        marginLeft: "2vw",
        marginRight: "2vw",
        padding:"10px",
    },
    margin: {
        margin: theme.spacing(1),
    },
}));


const ModalCategorie = ({ open, onClose, ShowCategorieModal }) => {
        
    //styling
    const classes = useStyles();
    const [displayForm, setDisplayForm] = useState("initial");
    const [saveColor,setSaveColor] = useState({fill: "#1976d2",fontSize: 30})

    //type flag select
    const [typeFlagSelect, setTypeFlagSelect] = useState([]); 

    //modal
    const [libelleFlag, setLibelleFlag] = useState([]);
    const [refreshLibelle, setRefreshLibelle] = useState(false);
    useEffect(() => {

        const fetchAssocFlagIndicateur = async (param) => {
            if(param === null || param === undefined){
                return
            }
            try {
                const dataAssocFlagIndicateur = await serviceApi.sortAssocFlagIndicateurByIdIndic(param);
                let AssocFlagIndicateurList = [];
                dataAssocFlagIndicateur.forEach(element => {
                    AssocFlagIndicateurList.push({
                        value: element.idtypeFlag.libelle.toUpperCase(),
                        id: element.idtypeFlag.idtypeFlag,
                        key: element.idtypeFlag.libelle + element.idtypeFlag.idtypeFlag,
                        idAssocFlagIndicateur: element.idassocFlagIndicateur
                    })
                });
                
                
                
                const dataTypeFlagSelect = await serviceApi.findAllTypeFlagSelect();
                let TypeFlagSelectList = [];
                dataTypeFlagSelect.forEach(element => {
                    TypeFlagSelectList.push({
                        value: element.idtypeFlag,
                        name: element.libelle,
                        id: element.idtypeFlag
                    });
                });
                
                //filtering the select 
                let TypeFlagSelectListB = [];
                
                for (let i = 0; i <TypeFlagSelectList.length; i++){
                    TypeFlagSelectListB.push(TypeFlagSelectList[i])
                    for (let j = 0; j <AssocFlagIndicateurList.length; j++){
                        if(TypeFlagSelectList[i]['id'] === AssocFlagIndicateurList[j]['id']){
                            TypeFlagSelectListB.pop(TypeFlagSelectList[i]);
                        }
                    }
                }
                setLibelleFlag(AssocFlagIndicateurList);
                setTypeFlagSelect(TypeFlagSelectListB);                
               

                //display form
                TypeFlagSelectListB.length === 0  ?  setDisplayForm("none") : setDisplayForm("initial") ;
            }catch(error){
                console.log(error)
            }

        };
        fetchAssocFlagIndicateur(ShowCategorieModal.id);
    },[open,onClose,ShowCategorieModal,refreshLibelle]);
    
    //on change flag
    const [flagValue, setFlagValue] = useState('');
    const handleChangeFlag = async (event) => {
        event.preventDefault();        
        const typeIndicId = ShowCategorieModal.id;
        setSaveColor({fill: "#4caf50",fontSize: 30})
        //show error 
        try {
            await serviceApi.createTypeFlag(flagValue,typeIndicId);
            setRefreshLibelle(!refreshLibelle);
            setSaveColor({fill: "#4caf50",fontSize: 30})
        }catch(error){
            console.log(error);
        }
    }
    //deleteFlag
    const deleteFlag = async (param) => {
        try {
            await serviceApi.deleteTypeFlag(param.idAssocFlagIndicateur);
            setRefreshLibelle(!refreshLibelle);
        }catch(error){
            console.log(error)
        }
    }

    //render
    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-modalCategorie"
                aria-describedby="alert-dialog-slide-modalCategorie"
                maxWidth="sm"
                fullWidth
                id="modalCategorie"

            >
                <Paper elevation={0} >
                    <DialogTitle style={{"textAlign":"center"}} id="alert-dialog-title">{"Associer une nouvelle catégorie".toUpperCase()}</DialogTitle>
                    <DialogContent>
                        <Typography component="div" align={'center'} id="alert-dialog-description">
                        {ShowCategorieModal.libelle ? <Typography variant="subtitle1" component="p">Indicateur concerné : {ShowCategorieModal.libelle}</Typography>  : ""}
                        </Typography>
                    </DialogContent>
                        <Box
                            className={classes.container}
                        >
                            <Grid 
                            container
                            direction="row"
                            alignItems="baseline"
                            justify="center"                    
                            spacing={1}
                            >  
                                    {libelleFlag.map((flag,i) => (
                                        <React.Fragment key={i}>
                                        <Grid item xs={4} sm={4}  >
                                            <Box>
                                            <InputLabel id="InputLabel-label">Libellé :</InputLabel>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} sm={4}  >
                                            <Box>
                                                    <Typography
                                                        display="inline"
                                                        variant="body1" 
                                                        component="p"
                                                        gutterBottom
                                                    >        
                                                        {flag.value}
                                                    </Typography  >    
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} sm={4}   >
                                        <Tooltip title="delete" aria-label="del-indicateur">
                                            <IconButton  
                                                color="secondary" 
                                                size="medium"
                                                edge="end"
                                                value={flag.id}
                                                onClick={() => { deleteFlag(flag); }} 
                                            >
                                                <DeleteOutlineIcon />
                                            </IconButton >
                                        </Tooltip>
                                    </Grid> 
                                        </React.Fragment>
                                    ))}
                            </Grid>  
                            <form  id="login" className={classes.formControl} onSubmit={event => handleChangeFlag(event)} style={{display : displayForm}}>
                                <Grid 
                                    container
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center"
                                    spacing={2}
                                >

                                    <Grid item xs={6} sm={6}  >
                                        <Select
                                            labelId="select-flag-required-label"
                                            id="select-flag-required"
                                            defaultValue="_all"
                                            value={typeFlagSelect.value}
                                            autoWidth
                                            className={classes.selectEmpty}   
                                            onChange={(e) => setFlagValue(e.target.value)}
                                            variant="outlined"
                                        >
                                            <MenuItem value="_all" ><em>Ajouter une catégorie</em></MenuItem>
                                            {typeFlagSelect.map((flag) => (
                                                <MenuItem key={flag.id} value={flag.value} name={flag.name}>{flag.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={6} sm={6}   >
                                        <Tooltip title="Ajouter un indicateur" aria-label="add-indicateur">
                                            <IconButton  
                                                type="submit" 
                                                color="primary" 
                                                size="medium"
                                                edge="end"
                                            >
                                                <SaveIcon style={saveColor}/>
                                            </IconButton >
                                        </Tooltip>
                                    </Grid>   
                                </Grid>
                            </form>   
                            {(displayForm === 'none') ? <Typography align="center" variant='subtitle1' color="textSecondary" style={{ padding:'20px'}}>*Toutes les catégories sont associées</Typography > : null}  
                        </Box>

                    <DialogActions disableSpacing >
                        <Button onClick={onClose} color="secondary" autoFocus>
                            Fermer
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
        </div>
    )
}

export default ModalCategorie;