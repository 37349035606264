import axios from "axios";
import {ASSOC_USER_TYPE_INDICATEUR} from "../config.js";

function findAllAssocUserTypeIndicteur(){
    return axios
        .get(ASSOC_USER_TYPE_INDICATEUR)
        .then(response => response.data['hydra:member']);
}
const sortByTypeIndicateurUsername = async (typeIndicId,username) => {
    try {
        const allData = await axios
            .get(ASSOC_USER_TYPE_INDICATEUR + "?idtypeindicateur.id=" + typeIndicId + "&iduser.email=" + username)
            .then(response => response.data['hydra:member']);

        return allData
    }
    catch (err) {
        console.log(err);
    }
}

export default {
    findAllAssocUserTypeIndicteur,
    sortByTypeIndicateurUsername
}