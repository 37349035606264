import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import jwtDecode from "jwt-decode";

import Account from './account.js';
import RightsManagement from './rightsmanagement.js';
import ManageableLists from './manageablelists.js';

import { Grid, 
         Typography,
         Paper,
         Tabs,
         Tab
        } from '@material-ui/core';
//styling
import * as styling from '../services/styling'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        //marginTop: '25vh',
    },
    titleText : {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop:'25px',
        color : styling.colorTextTitle,
        textTransform: "uppercase"
    },
    page : {
        marginTop: '50px',
    }
}));

const Administration = () => {
    const classes = useStyles();

    const [value, setValue] = useState(0);
    const [roleSuperAdmin, setRoleSuperAdmin] = useState(false);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);        
    };

    useEffect(() => {
        const getDecodedToken = () => {
            const tokenBrut = window.localStorage.getItem("authToken");
            if(tokenBrut){
                const decodedToken = jwtDecode(tokenBrut);
                if(decodedToken){
                    (decodedToken.roles[0] === "SUPER_ADMIN" || decodedToken.roles[0] === "ADMIN" ) ? setRoleSuperAdmin(true) : setRoleSuperAdmin(false);         
                }
            }
        }
        getDecodedToken();
    }, []);



    return (
        <>  
            <div className="corners">
                <div className="top left"></div>
                <div className="top right"></div>
                <div className="bottom right"></div>
                <div className="bottom left"></div>
                <Typography variant="h4" align='center' className={classes.titleText}>Administration</Typography >
            </div>      
            <div className={classes.page}>      
                <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={12} lg={12}>
                        <Paper className={classes.root}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label="Gestion des droits" />
                                <Tab label="Gestion des comptes" />
                                {roleSuperAdmin === true ? <Tab label="Listes administrables" /> : null }
                            </Tabs>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={12}>                    
                            {value === 1 && <Account /> }
                            {value === 2 && <ManageableLists /> } 
                            {value === 0 && <RightsManagement /> }
                        
                    </Grid> 
                </Grid>
            </div> 
        </>
    )
}

export default Administration;
