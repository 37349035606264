import React, { useState, useEffect } from 'react';
//material-ui
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
//swal
import Swal from 'sweetalert2';
//query
import serviceApi from '../services/ServiceApi';
import { SnackbarContent } from '@material-ui/core';

//style
const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        minWidth: 100,
    },

  }));

  const ModalResponsable = ({ open, onClose, ShowResponsableModal, refreshParent }) => {


    //styling
    const classes = useStyles();

    //show/hide
    const [showResponsable, setShowResponsable] = useState({});
    const [showSelect, setShowSelect] = useState({})
    //responsable stored
    const [responsableStored, setResponsableStored] = useState({});

    useEffect(() => {
        const fetchResponsable = async (param) => {
            try{
                const dataTypeIndicateur = await serviceApi.findTypeIndicateurById(param);
                
                if(dataTypeIndicateur.idResponsable !== undefined){
                    setResponsableStored({
                        name : dataTypeIndicateur.idResponsable.nomResponsable,
                        value: dataTypeIndicateur.idResponsable.nomResponsable,
                        id : dataTypeIndicateur.idResponsable.idResponsable
                    });
                }
                
            }catch(err){
                console.log(err)
            }
        };
        
        if(undefined !== ShowResponsableModal.id ){
            fetchResponsable(ShowResponsableModal.id);
        }
        if (undefined !== ShowResponsableModal.idResponsable) {
            setShowResponsable({'visibility':'visible'});
            setShowSelect({'display':'none'})
        }else{
            setShowResponsable({'visibility':'hidden'});
            setShowSelect({'display':'initial'});
        }
    },[open,onClose,ShowResponsableModal]);

    //Responsables list
    const [responsable, setResponsable] = useState([]);

    useEffect(() => {
        const fetchResponsables = async () => {
            try {
            const dataResponsable = await serviceApi.findAllResponsable();
            let responsableSelectList = [];
            dataResponsable.forEach(element => {
                responsableSelectList.push({
                    value: element.idResponsable,
                    name: element.nomResponsable,
                    id: element.nomResponsable + element.idResponsable  
                });
            });
            setResponsable(responsableSelectList);

            } catch (err) {
                console.log(err);
            }
        }
        fetchResponsables(ShowResponsableModal)
    },[open,onClose,ShowResponsableModal]);



    //delete
    const unsetResponsable = async () => {
        try{
            await serviceApi.removeTypeIndicateurResponsable(ShowResponsableModal.id);
            refreshParent();
            Swal.fire(
                'Supprimé !',
                '',
                'success'
              )
        }catch(err){
            console.log(err);
        }
    }
    const deleteResponsable = () => {

        onClose(); //close previous modal
        Swal.fire({
            title: 'Êtes-vous sûr ?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c60A3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer !',
            cancelButtonText: 'Non  '
          }).then((result) => {
            if (result.value) {
                unsetResponsable();
            }
          })
    }
    //submit
    const [responsableSelect, setResponsableSelect] = useState('');

    const handleSubmit = async (event) => {            
        event.preventDefault();
        try {
            await serviceApi.addTypeIndicateurResponsable(responsableSelect,ShowResponsableModal.id);
            refreshParent();
            onClose();
        }catch(error){
            console.log(error);
        }
    }

    
    
    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-modalResponsable"
                aria-describedby="alert-dialog-slide-modalResponsable"
                maxWidth="sm"
                fullWidth
                id="modalResponsable"

            >
                <Paper elevation={0} >
                        <DialogContent>
                            <Container component="main" maxWidth="sm">
                                <CssBaseline />
                                <div className={classes.paper}>
                                        <Avatar className={classes.avatar}>
                                            <PeopleAltIcon />
                                        </Avatar>
                                        <Typography component="h1" variant="h5">
                                            {"Associer un référent".toUpperCase()}
                                        </Typography>

                                        <form className={classes.form}
                                            noValidate
                                            style={{ minWidth: 120 }}
                                            onSubmit={event => handleSubmit(event)}
                                            autoComplete="off"
                                        >           
                                        <List component="div" aria-label="liste responsable" >
                                        <ListItem 
                                            button
                                            onClick={() => { deleteResponsable()}} 
                                            style={showResponsable}
                                            >
                                            <Tooltip title="Cliquez pour supprimer l'association">
                                                <ListItemIcon>
                                                    <DeleteIcon />
                                                </ListItemIcon>
                                            </Tooltip>
                                            <ListItemText primary={responsableStored ? responsableStored.name : null } />
                                            </ListItem>
                                        </List>                                
                                        <FormControl className={classes.form} >
                                        <Select
                                            style={showSelect}
                                            variant="outlined"
                                            labelId="select-responsable-required-label"
                                            id="select-responsable-required"
                                            value={responsable.name}
                                            defaultValue=""
                                            onChange={(e) => {
                                                setResponsableSelect(e.currentTarget.getAttribute('id'));
                                            }}
                                            className={classes.selectEmpty}                                            
                                        >
                                            {responsable.map((resp) => (
                                                <MenuItem key={resp.id} value={resp.name} id={resp.value} name={resp.name}>{resp.name}</MenuItem>
                                            ))}
                                        </Select>
                                        </FormControl>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            style={showSelect}
                                        >
                                            Valider
                                        </Button>
                                    </form>
                                </div>
                            </Container>
                        </DialogContent>
                    <DialogActions disableSpacing >
                        <Button onClick={onClose} color="secondary" autoFocus>
                            Fermer
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
        </div>
    )
  }
  export default ModalResponsable;