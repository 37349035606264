import axios from "axios";
import {ASSOC_USER_TYPE_INDICATEUR} from "../config.js";
import userData from "./UserApi";

// give access to type user creator
const setAccessUserTypeIndic = async (idTypeIndicateur,userName) => {
    const uriTypeIndicateur = "/api/type_indicateurs/" + idTypeIndicateur;
    const uriUser = "/api/users/";
    let idUser;
    let data = {};
    try {
        const allUser = await userData.findAllUsers();
        //boucle sur le nom user  et  avoir l'id
        allUser.forEach((user) => {            
            if(user.email === userName){
                idUser = user.id;
            }
        })
        data = {
            "iduser": uriUser + idUser,
            "idtypeindicateur": uriTypeIndicateur,
            "lecture": true,
            "ecriture": true,
            "suppression": true,
        };
        //create
        axios
            .post(ASSOC_USER_TYPE_INDICATEUR,data)
            .then(response => response.data);

    }catch(err){
        console.log(err);
    }


}
// one by one
const toggleLecture = (rowdata, value) => {
    const uriTypeIndicateur = "/api/type_indicateurs/" + rowdata.id;
    const uriUser = "/api/users/" + rowdata.assocUserTypeIndicateurs.iduser
    let data = {};

    if (null !== rowdata.assocUserTypeIndicateurs.id) {
        data = {
            "id": rowdata.assocUserTypeIndicateurs.id,
            "iduser": uriUser,
            "idtypeindicateur": uriTypeIndicateur,
            "lecture": value,
        }
        //update 
        return axios
            .put(ASSOC_USER_TYPE_INDICATEUR + "/" + rowdata.assocUserTypeIndicateurs.id, data)
            .then(response => response.data);

    } else if (null === rowdata.assocUserTypeIndicateurs.id) {
        data = {
            "iduser": uriUser,
            "idtypeindicateur": uriTypeIndicateur,
            "lecture": value,
        }
        //create
        return axios
            .post(ASSOC_USER_TYPE_INDICATEUR,data)
            .then(response => response.data);
    }
}

const toggleEcriture = (rowdata, value) =>  {
    const uriTypeIndicateur = "/api/type_indicateurs/" + rowdata.id;
    const uriUser = "/api/users/" + rowdata.assocUserTypeIndicateurs.iduser
    let data = {};

    if (null !== rowdata.assocUserTypeIndicateurs.id) {
        data = {
            "id": rowdata.assocUserTypeIndicateurs.id,
            "iduser": uriUser,
            "idtypeindicateur": uriTypeIndicateur,
            "ecriture": value,
        }
        //update 
        return axios
            .put(ASSOC_USER_TYPE_INDICATEUR + "/" + rowdata.assocUserTypeIndicateurs.id, data)
            .then(response => response.data);

    } else if (null === rowdata.assocUserTypeIndicateurs.id) {
        data = {
            "iduser": uriUser,
            "idtypeindicateur": uriTypeIndicateur,
            "ecriture": value,
        }
        //create
        return axios
            .post(ASSOC_USER_TYPE_INDICATEUR,data)
            .then(response => response.data);
    }
}

const toggleSuppression =  (rowdata, value) => {
    const uriTypeIndicateur = "/api/type_indicateurs/" + rowdata.id;
    const uriUser = "/api/users/" + rowdata.assocUserTypeIndicateurs.iduser
    let data = {};

    if (null !== rowdata.assocUserTypeIndicateurs.id) {
        data = {
            "id": rowdata.assocUserTypeIndicateurs.id,
            "iduser": uriUser,
            "idtypeindicateur": uriTypeIndicateur,
            "suppression": value,
        }
        //update 
        return axios
            .put(ASSOC_USER_TYPE_INDICATEUR + "/" + rowdata.assocUserTypeIndicateurs.id, data)
            .then(response => response.data);

            console.log("data update", data)
    } else if (null === rowdata.assocUserTypeIndicateurs.id) {
        data = {
            "iduser": uriUser,
            "idtypeindicateur": uriTypeIndicateur,
            "suppression": value,
        }
        //create
        return axios
            .post(ASSOC_USER_TYPE_INDICATEUR,data)
            .then(response => response.data);
    }
}

// for all users
function toggleLectureAll(entries,param) {    
    entries.forEach(element => {
        toggleLecture(element,param);
    })
    return
}

function toggleEcritureAll(entries,param) {
    entries.forEach(element => {
        toggleEcriture(element,param);
    })
    return
}

function toggleSuppressionAll(entries,param) {
    entries.forEach(element => {
        toggleSuppression(element,param);
    })
    return
}

// delete one 
function deleteOne() {
    return
}


export default {
    setAccessUserTypeIndic,
    toggleLecture,
    toggleEcriture,
    toggleSuppression,
    toggleLectureAll,
    toggleEcritureAll,
    toggleSuppressionAll,
    deleteOne

}