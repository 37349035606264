import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      marginTop: '15vh',
      marginLeft: '5vw'
    },
}));

const AutresBases = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            AutresBases
        </div>
    )
}

export default AutresBases;