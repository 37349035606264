import React, { useState, useEffect } from 'react';
//material-ui
import { makeStyles } from '@material-ui/core/styles';
//checkbox 
import Checkbox from '@material-ui/core/Checkbox';
//material-table
import MaterialTable, { MTableToolbar } from 'material-table';
//dates picker
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import frLocale from "date-fns/locale/fr";

//input text field
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
//icons
import { FcList } from "react-icons/fc";
import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import { HiOutlineDocumentDuplicate } from "react-icons/hi";

//modal 
import ModalIndicateurs from './modalIndicateurs';
//component 
import IndicateurField from './indicateurField';
import IndicateurCategorieField from './indicateurCategorieField';
//query
import serviceApi from '../services/ServiceApi';
import categorieFlag from '../services/categorieFlag';
//styling
import * as styling from '../services/styling';
//alert 
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles(theme => ({

    table: {
        padding: '30px'
    },
    /*     formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        }, */
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    toolbar: {
        backgroundColor: styling.backGroundHeader,
    }
}));

const IndicateurTable = ({ ShowSecondTable, refreshParent }) => {
    //styling
    const classes = useStyles();

    //isLoading
    const [chargement, setChargement] = useState({
        isLoading: false
    });

    //typeObjectif ID
    const typeIndicateurID = ShowSecondTable.id;


    const [typeUnite, setTypeUnite] = useState('');

    //input text error
    const [nameError, setNameError] = React.useState({
        error: false,
        label: "",
        helperText: "",
        validateInput: false,
    });

    //error
    const [openAlert, setOpenAlert] = useState(false);
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    //row data modal
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialogName, setOpenDialog] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const closeDialog = () => {
        setOpenDialog(null);
        setRefreshTab(!refreshTab);
    };
    const openIndicateur = () => {
        setOpenDialog('openIndicateur');
        handleClose();
    };

    const [sendselectedRow, setSendselectedRow] = useState([]);
    const ShowIndicateurModal = (param) => {
        setSendselectedRow([param]);
    }


    //duplicate row
    const duplicateRow = async (param) => {
        const data = {
            commentaire: param.commentaire ? param.commentaire : null,
            commentaireNonpbi: param.commentaireNonpbi ? param.commentaireNonpbi : null,
            date: param.date,
            isBoolean: param.isBoolean ? param.isBoolean : null,
            isFloat: param.isFloat ? param.isFloat : null,
            isText: param.isText ? param.isText : null,
            typeIndicateurId: param.typeIndicateurId,
            typeUnite: param.typeUnite,
            valeur: param.valeur ? param.valeur : null
        };
        try {
            const responseDuplicate = await serviceApi.createOneIndicateur(data, param.typeIndicateurId, param.typeUnite);
            await serviceApi.duplicateAssocFlag(param.id,responseDuplicate.id,responseDuplicate.idTypeIndicateur.id);
    
            setRefreshTab(!refreshTab);
            
        } catch (error) {
            console.log(error);
        }
    }

    //data use in the datatable
    const [entries, setEntries] = useState([]);


    //reload
    const [refreshTab, setRefreshTab] = useState(false);

    //role
    const [canAdd, setCanAdd] = useState(false);
    // type indic
    const [dataParent, setDataParent] = useState(new Map());
    useEffect(() => {
        const fetchsecondIndic = async (param) => {
            setChargement({
                isLoading: true
            });
            //const dataIndic = await serviceApi.findAllIndicateurs();
            const responseTypeIndicateur = await serviceApi.findTypeIndicateurById(param.id);
            setDataParent(prevState => prevState.set("responseTypeIndicateur", responseTypeIndicateur));
            const responseData = await serviceApi.sortIndicateurByTypeCategories(param.id);
            let indicateurTab = [];
            let indicateurArray = [];
            if (responseData.length > 0) {
                responseData.forEach(element => {
                    indicateurTab.push({
                        id: element.id,
                        date: element.date,
                        typeIndicateurId: element.idTypeIndicateur.id,
                        valeur: element.valeurTxt ? element.valeurTxt : element.valeurFloat,
                        isFloat: element.idTypeIndicateur.typeUnite.isFloat,
                        isText: element.idTypeIndicateur.typeUnite.isText,
                        isBoolean: element.idTypeIndicateur.typeUnite.isBoolean,
                        typeUnite: element.idTypeIndicateur.typeUnite.libelleUnite,
                        commentaire: element.commentaire,
                        commentaireNonpbi: element.commentaireNonpbi,
                        access: {
                            role: param.access.role,
                            lecture: true,
                            ecriture: true,
                            suppression: true
                        },
                        accessUser: {
                            role: param.access.role,
                            lecture: (param.access.role === "USER") ? param.accessUser.lecture : param.access.lecture,
                            ecriture: (param.access.role === "USER") ? param.accessUser.ecriture : param.access.ecriture,
                            suppression: (param.access.role === "USER") ? param.accessUser.suppression : param.access.suppression
                        },
                        categorie: element.categorie
                    });
                    setCanAdd((param.access.role === "USER") ? param.accessUser.ecriture : param.access.ecriture)

                    //get categorie
                    indicateurArray.push({
                        id: element.id,
                        typeIndicateurId: element.idTypeIndicateur.id
                    })
                });
            }
            //if empty array, set default write permissions 
            if(param.access && indicateurTab.length === 0) {
                setCanAdd((param.access.role === "USER") ? param.accessUser.ecriture : true );       
            }

            //animation
            setChargement({
                isLoading: false
            });
            
            //set Unité
            setTypeUnite(param.typeUnite ? param.typeUnite : "");
            //set table entries 
            setEntries(indicateurTab);

        };
        fetchsecondIndic(ShowSecondTable);
    }, [ShowSecondTable, refreshTab]);

    //table column definition\\
    const [columnDefinition, setColumnDefinition] = useState({
        columns: [
            {
                title: "Date de mise à jour", field: "date", type: "date", width: "10%",
                headerStyle: {
                    width: "10%",
                    maxWidth: "10%",
                    textTransform: "uppercase",
                },
                editComponent: (props) => (
                    <>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    value={props.value ? props.value : ""}
                                    onChange={(e) => {
                                        props.onChange(e);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </>
                )
            },
            {
                title: "Unité", field: 'typeUnite', editable: "never", width: "10%",
                headerStyle: {
                    width: "10%",
                    maxWidth: "10%",
                    textTransform: "uppercase",
                },

            },
            {
                title: "Valeur", field: 'valeur',
                headerStyle: {
                    width: "10%",
                    maxWidth: "10%",
                    textTransform: "uppercase",
                },
                editComponent: (props) => {

                    const parent = dataParent.get("responseTypeIndicateur");
                    
                    let ischecked;
                    let value ;
                    let type;
                    parent.typeUnite.isFloat === true ? type = "number" : type = "string";
                    if (parent.typeUnite.isBoolean === true ) {
                        props.rowData.valeur === 1 ? ischecked = true : ischecked = false;
                        return <Checkbox
                            checked={ischecked ? true : false}
                            onChange={(e) => {
                                e.target.checked === true ? value = 1 : value = 0;
                                props.onChange(value);
                            }}
                        />
                    } else {
                        return (<form className={classes.inputForm} noValidate autoComplete="off">
                            <TextField
                                className={classes.formControl}
                                required
                                type={type}
                                size="small"
                                variant="filled"
                                error={
                                    !props.value &&
                                        nameError.validateInput &&
                                        props.rowData.submitted
                                        ? nameError.error
                                        : false
                                }
                                helperText={
                                    !props.value &&
                                        nameError.validateInput &&
                                        props.rowData.submitted
                                        ? nameError.helperText
                                        : ""
                                }
                                value={props.value ? props.value : ""}
                                onChange={(e) => {
                                    if (nameError.validateInput) {
                                        setNameError({
                                            ...nameError,
                                            validateInput: false,
                                        });
                                    }
                                    props.onChange(e.target.value);
                                }}
                            />
                            <FormHelperText>Requis</FormHelperText>
                        </form>)
                    }
                }
                ,
                render: rowData => {
                    
                    if (rowData.isBoolean === true) {
                        if (rowData.valeur === 1) {
                            return <div>Oui</div>
                        } else if (rowData.valeur === 0) {
                            return <div>Non</div>
                        } else {
                            return <div>-</div>
                        }
                    } else {
                        return rowData.valeur
                    }
                }
            },
            {
                title: "Catégorie", editable: 'never',sorting: false,
                headerStyle: {
                    width: "10%",
                    maxWidth: "10%",
                    textTransform: "uppercase",
                },
                render: rowData => {
                    let data=[];
                    if (rowData !== undefined && rowData.categorie.length > 0) {
                        rowData.categorie.map(cat => {
                            
                                data.push(<Box >
                                    <p >{cat.libelle} : <br />
                                - {cat.valeur_flag}</p>
                                </Box>)
                          
                        })

                    } else {
                        return
                    }

                    return data.map((cat,i) =><Box key={i}>{cat}</Box>)
                }
            },
            {
                title: "Commentaire à afficher sur la DataViz", field: 'commentaire', cellStyle: {
                    width: "10%",
                    maxWidth: "10%"
                },
                headerStyle: {
                    width: "10%",
                    maxWidth: "10%",
                    textTransform: "uppercase",
                },
                editComponent:(props) => (
                    <TextField
                    id="outlined-multiline-afficher"
                    label="Commentaire"
                    multiline
                    className={classes.selectFormControl}
                    rows={4}
                    defaultValue={props.value}
                    variant="outlined"
                    onChange={(e)=>{
                        props.onChange(e.target.value);
                    }}
                  />
                ),
            },
            {
                title: "Commentaire caché (non reporté en dataviz)", field: 'commentaireNonpbi', cellStyle: {
                    width: "10%",
                    maxWidth: "10%",
                },
                headerStyle: {
                    width: "10%",
                    maxWidth: "10%",
                    textTransform: "uppercase",
                },
                editComponent:(props) => (
                    <TextField
                    id="outlined-multiline-caché"
                    label="Commentaire"
                    multiline
                    className={classes.selectFormControl}
                    rows={4}
                    defaultValue={props.value}
                    variant="outlined"
                    onChange={(e)=>{
                        props.onChange(e.target.value);
                    }}
                  />
                ),
            },
        ],
    });
    // Row add , edit or  delete
    const addNewRow = async (param) => {

        try {
            await serviceApi.createOneIndicateur(param, dataParent.get("responseTypeIndicateur").id, typeUnite);
            setRefreshTab(!refreshTab);
        } catch (error) {
            console.log(error);
        }
    }
    const updateRow = async (param) => {
        try {
            await serviceApi.updateRowIndicateur(param);
            setRefreshTab(!refreshTab);
        } catch (error) {
            console.log(error);
        }
    }
    const deleteRow = async (param) => {
        try {
            await serviceApi.deleteIndicateur(param);

        } catch (error) {
            console.log(error)
        }
    }
    const handleRowClick = (e, rowData) => {
        e.preventDefault();    
    }
    return (
        <>
            <div className={classes.table} >
                <MaterialTable

                    title={'RENSEIGNER UN INDICATEUR'}
                    columns={columnDefinition.columns}
                    data={entries}
                    isLoading={chargement.isLoading}
                    onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                    components={{
                        Toolbar: props => (
                            <div style={{ color: styling.colorTextHeader }}>
                                <MTableToolbar {...props} classes={{ root: classes.toolbar }} />
                            </div>
                        ),
                    }}
                    options={{
                        rowStyle: {
                            backgroundColor: styling.tableRowBackgroundColor,
                        },
                        headerStyle: {
                            backgroundColor: styling.tableHeaderBackgroundColor,
                            color: styling.tableHeaderColor
                        },
                        pageSize: 12,
                        pageSizeOptions: [12, 24, 48, { label: 'Tout', value: entries.length }],
                        //actionsColumnIndex: -1, //action column o right side
                        addRowPosition: 'first', //add row on top
                        padding:'dense'
                    }}
                    style={{
                        color: styling.tableTextColor,
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: "{from} à {to} sur {count}",
                            labelRowsSelect: "résultats par page",
                            labelRowsPerPage: "résultats par page",
                            firstAriaLabel: "Première page",
                            firstTooltip: "Première page",
                            previousAriaLabel: "Page précédente",
                            previousTooltip: "Page précédente",
                            nextAriaLabel: "Page suivante",
                            nextTooltip: "Page suivante",
                            lastAriaLabel: "Dernière page",
                            lastTooltip: "Dernière page"
                        },
                        toolbar: {
                            addRemoveColumns: "Ajouter ou supprimer des colonnes",
                            nRowsSelected: "{0} ligne(s) sélectionnée(s)",
                            showColumnsTitle: "Afficher les colonnes",
                            showColumnsAriaLabel: "Afficher les colonnes",
                            exportTitle: "Exporter",
                            exportAriaLabel: "Exporter",
                            exportName: "Exporter en CSV",
                            searchTooltip: "Recherche",
                            searchPlaceholder: "Recherche"
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage:
                                "Désolé, aucun enregistrement correspondant n'a été trouvé",
                            addTooltip: "Ajouter une ligne",
                            deleteTooltip: "Supprimer",
                            editTooltip: "Modifier",
                            filterRow: {
                                filterTooltip: "Filtrer"
                            },
                            editRow: {
                                deleteText: "Êtes-vous sûr de vouloir supprimer cet élément ? Vous allez perdre TOUTES les données associées",
                                cancelTooltip: "Annuler",
                                saveTooltip: "Sauvegarder"
                            }
                        },
                    }}
                    icons={{
                        Add: props => <AddCircleRounded {...props} style={{ fill: styling.tabButtonAddRow }} />,
                        Edit: props => <Edit  {...props} style={{ fill: "#ff9800" }} />,
                        Delete: props => <DeleteForeverIcon   {...props} style={{ fill: "#d50000" }} />,
                        Check: props => <Check   {...props} style={{ fill: "#2e7d32" }} />,
                        Clear: props => <Clear    {...props} style={{ fill: "#d50000" }} />,
                    }}
                    actions={[
                        rowData => ({
                            icon: () => <FcList style={{ fill: styling.tabButtonAddRow }} />,
                            tooltip: 'Catégoriser l\'indicateur',
                            onClick: (event, rowData) => {
                                setSendselectedRow(rowData);
                                openIndicateur();
                            },
                            //display buttons according to role
                            disabled: !rowData.accessUser.ecriture,
                            hidden: !rowData.accessUser.ecriture
                        }),
                        rowData => ({
                            icon: () => <HiOutlineDocumentDuplicate style={{ color: "#42B348" }} />,
                            tooltip: 'Dupliquer l\'indicateur',
                            onClick: (event, rowData) => {
                                duplicateRow(rowData);
                            },
                            //display buttons according to role
                            disabled: !rowData.accessUser.ecriture,
                            hidden: !rowData.accessUser.ecriture
                        }),
                    ]}
                    editable={{
                        //display buttons according to role
                        //isEditable: rowData => roleIndicateur.canEdit(rowData), // only name(a) rows would be editable
                        isEditHidden: rowData => !rowData.accessUser.ecriture, //only if rowData.typeIndicateurId match
                        //isDeletable: rowData => rowData.sousCategorie === 'Communication', // only name(b) rows would be deletable,
                        isDeleteHidden: rowData => !rowData.accessUser.suppression, //only if rowData.typeIndicateurId match

                        onRowAdd: canAdd ? (newData) =>
                            new Promise((resolve, reject) => {
                                newData.submitted = true;
                                if (!newData.date) {
                                    setNameError({
                                        error: true,
                                        label: "champs requis",
                                        helperText: "erreur",
                                        validateInput: true,
                                    });
                                    setOpenAlert(true);
                                    reject();
                                    return;
                                }

                                addNewRow(newData);
                                resolve();

                            }) : null,
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    newData.submitted = true;
                                    if (!newData.date) {
                                        setNameError({
                                            error: true,
                                            label: "champs requis",
                                            helperText: "erreur",
                                            validateInput: true,
                                        });
                                        setOpenAlert(true);
                                        reject();
                                        return;
                                    }

                                    const dataUpdate = [...entries];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    setEntries([...dataUpdate]);
                                    updateRow(newData);
                                    resolve();
                                }, 1000);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                const dataDelete = [...entries];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setEntries([...dataDelete]);
                                deleteRow(oldData.id);
                                resolve();

                            }),
                    }}
                />
            </div>
            <div>
                <ModalIndicateurs
                    open={openDialogName === 'openIndicateur'}
                    onClose={closeDialog}
                    ShowIndicateurModal={sendselectedRow}
                />
            </div>
            <Snackbar 
                open={openAlert} 
                autoHideDuration={6000} 
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert variant="filled" severity="error">
                    Veuillez vérifier votre saisie.
                </Alert>
            </Snackbar>
        </>
    )
}



export default IndicateurTable;
