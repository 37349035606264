import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
//image
import imgAccueil from '../images/imgCo.png';

const useStyles = makeStyles(theme => ({
    root: {
        height: '90vh',
        backgroundColor:'white', 
    },

    image: {
        backgroundImage: "url(" + imgAccueil + ")",
        backgroundRepeat: 'no-repeat',
        backgroundColor:
           'white',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    imageInside : {
        backgroundColor:'white', 
        height: 'auto',
        width: '100%',
        paddingLeft : '20%',
        paddingRight : '20%',
        paddingTop: '50px',
    }
}));

const Accueil = () => {
    const classes = useStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={12} md={12} /* className={classes.image} */> 
                <img src={imgAccueil} className={classes.imageInside} />
            </Grid>
        </Grid>
    )
}

export default Accueil;