import axios from "axios";
import * as All from '../config';
import CreateObjectif from '../services/DateObjectifs';
import CreateIndicateur from '../services/DateIndicateurs';
import rightAPI from '../services/rightApi';
//findall
function findAllObjectifs() {
    return axios
        .get(All.ALL_OBJECTIFS)
        .then(response => response.data['hydra:member']);
}
function findAllTypeObjectifs() {
    return axios
        .get(All.TYPE_OBJECTIFS_API)
        .then(response => response.data['hydra:member']);
}
function findAllDates() {
    return axios
        .get(All.TYPE_DATE_API)
        .then(response => response.data['hydra:member']);

}
function findAllUnites() {
    return axios
        .get(All.TYPE_UNITE_API)
        .then(response => response.data['hydra:member']);
}
function findAllTypeIndicateurs() {
    return axios
        .get(All.TYPE_INDICATEUR_API)
        .then(response => response.data['hydra:member']);
}
function findAllIndicateurs() {
    return axios
        .get(All.ALL_INDICATEURS)
        .then(response => response.data['hydra:member']);
}
function findAllCategorie() {
    return axios
        .get(All.TYPE_CATEGORIE_API)
        .then(response => response.data['hydra:member']);
}
function findAllSousCategorie() {
    return axios
        .get(All.TYPE_SOUS_CATEGORIE_API)
        .then(response => response.data['hydra:member']);
}
function findAllResponsable() {
    return axios
        .get(All.ALL_RESPONSABLE)
        .then(response => response.data['hydra:member']);
}

function findAllTypeFlagSelect() {
    return axios 
    .get(All.ALL_TYPE_FLAG)
    .then(response => response.data['hydra:member']);
}

function findAllFlagIndicateurValues() {
    return axios 
    .get(All.ALL_FLAG_INDICATEUR_VALUES)
    .then(response => response.data['hydra:member']);
}


//findById

function findCategorieById(parameters) {
    return axios
        .get(All.TYPE_CATEGORIE_ID_API + parameters)
        .then(response => response.data);
}

function findObjectifbyId(param) {
    return axios.get(All.ONE_OBJECTIF + param).then(response =>response.data)
}

function findTypeIndicateurById(param){
    return axios.get(All.TYPE_INDICATEUR_API + "/" + param).then(response =>response.data)
}
function findTypeObjectifById(param){
    return axios.get(All.TYPE_OBJECTIFS_API + "/" + param).then(response =>response.data)
}

//create 

function createIndicateurs (param){
    return axios
    .post(All.ALL_INDICATEURS, param)
    .then(response => response.data);
}


const createTypeIndicateur = async (param,userToken,idSousCat) => {

    try {
        const typeDate = await findAllDates();
        const typeUnite = await findAllUnites();
        const typeSousCat = await findAllSousCategorie();

        for (let k = 0; k < typeDate.length; k++) {
            if (typeDate[k].libelle === param.typeDate.libelle) {
                param.typeDate = "/api/type_dates/" + typeDate[k].id
            }
        }
        for (let l = 0; l < typeUnite.length; l++) {
            if (typeUnite[l].libelleUnite === param.typeUnite.libelleUnite) {
                param.typeUnite = "/api/type_unites/" + typeUnite[l].id
            }
        }

        param.idSousCategorie = "/api/sous_categorie_indicateurs/" + idSousCat;

        //create type indicateur
        const createTypIndic = await axios.post(All.TYPE_INDICATEUR_API, param).then(response => response.data);
        //set the access for the current user
        await rightAPI.setAccessUserTypeIndic(createTypIndic.id,userToken.username);
        //create the indicateurs
        CreateIndicateur.DateIndicateurs(createTypIndic);

    } catch (err) {
        console.log(err);
    }
    return
}

function createObjectifs (param){
    return axios
    .post(All.ALL_OBJECTIFS, param)
    .then(response => response.data);
}

const createTypeObjectif = async (param) => {
    try {
/*         const typeDate = await findAllDates();
        const typeUnite = await findAllUnites();
        for (let k = 0; k < typeDate.length; k++) {
            if (typeDate[k].libelle === param.typeDate) {
                param.typeDate = "/api/type_dates/" + typeDate[k].id
            }
        }
        for (let l = 0; l < typeUnite.length; l++) {
            if (typeUnite[l].libelleUnite === param.typeUnite) {
                param.typeUnite = "/api/type_unites/" + typeUnite[l].id
            }
        } */
        const createTypObj = await axios.post(All.TYPE_OBJECTIFS_API, param).then(response => response.data);
        CreateObjectif.DateObjectifs(createTypObj);

    } catch (err) {
        console.log(err);
    }

    return
}

const createOneObjectif = async (param, id, unite) => {
    try {
        let objToPost = {
            "date": param.date,
            "commentaire": param.commentaire ? param.commentaire : null,
            "commentaireNonpbi": param.commentaireNonpbi ? param.commentaireNonpbi : null,
            "valeurTxt": null,
            "valeurFloat": null,
            "idTypeObjectif": {
                'id' : id
            },
        };


        if (unite === "texte") {
            objToPost.valeurTxt = param.valeur;
        } else {
            objToPost.valeurFloat = parseInt(param.valeur, 10);
        }

        const postObj = await axios.post(All.ALL_OBJECTIFS, objToPost).then(response => response.data);

    } catch (err) {
        console.log(err);

    }

}
const createOneIndicateur = async (param, id, unite) => {
    try {
        let indicToPost = {
            "date": param.date,
            "commentaire": param.commentaire ? param.commentaire : null,
            "commentaireNonpbi": param.commentaireNonpbi ? param.commentaireNonpbi : null,
            "valeurTxt": null,
            "valeurFloat": null,
            "idTypeIndicateur": {
                'id' : id
            },
        };

        if (unite === "texte") {
            indicToPost.valeurTxt = param.valeur;
        } else {
            indicToPost.valeurFloat = parseInt(param.valeur, 10);
        }

        return axios.post(All.ALL_INDICATEURS, indicToPost).then(response => response.data);

    } catch (err) {
        console.log(err);

    }

}

function createTypeFlag(flagId,typeIndicId){

    const uriFlagID = "/api/type_flags/" + flagId;
    const uriTypeIndicId = "/api/type_indicateurs/" + typeIndicId;
    const data = {
        idtypeFlag : uriFlagID,
        idtypeIndicateur: uriTypeIndicId,
    };
    return axios
        .post(All.ASSOC_FLAG_INDICATEUR, data)
        .then(response => response.data);
}

const createTypeFlagIndicValue= async (flagId,typeIndicId,valFlag,indicId) => {
    try{
        const assocFlagByIndic = await sortAssocFlagIndicateurByIdIndic(typeIndicId);
        let assocFlagId;
        assocFlagByIndic.forEach(element => {
            if(element.idtypeFlag.idtypeFlag === flagId){
                assocFlagId = element.idassocFlagIndicateur;
            };
        });
        const uriassocFlag = "/api/assoc_flag_indicateurs/" + assocFlagId;
        const uriIndicateur = "/api/indicateurs/" + indicId; 
        const valFlagLowerCaseTrim = valFlag.name.toLowerCase().trim();
        const data = {
            idIndicateur: uriIndicateur,
            idassocFlagIndicateur: assocFlagId,
            valeurFlag: valFlagLowerCaseTrim
        };
        axios
            .post(All.ALL_FLAG_INDICATEUR_VALUES, data)
            .then(response => response.data);

    }catch(err){
        console.log(err);
    }
}
//update 

const updateRowTypeIndicateur = async (param,param2) => {

    try {
        const typeDate = await findAllDates();
        const typeUnite = await findAllUnites();
        const typeSousCat = await findAllSousCategorie();

        for (let k = 0; k < typeDate.length; k++) {
                if (typeDate[k].libelle === param.typeDate.libelle) {
                    param.typeDate = "/api/type_dates/" + typeDate[k].id
                }      
        }
        for (let l = 0; l < typeUnite.length; l++) {
                if (typeUnite[l].libelleUnite === param.typeUnite.libelleUnite) {
                    param.typeUnite = "/api/type_unites/" + typeUnite[l].id
                }
        }
        if(param2 !== "" ) {
            param.idSousCategorie = "/api/sous_categorie_indicateurs/" + param2;
        }else{
            console.log("issue")
        }
        
        let dataToUpdate = param;

        const updateTypeIndic = await axios
        .put(All.TYPE_INDICATEUR_API + "/" + dataToUpdate.id, dataToUpdate)
        .then(response => response.data);
        
        CreateIndicateur.DateIndicateurs(updateTypeIndic);

    }catch(err) {
        console.log(err)
    }
}

const updateRowTypeObjectif = async (param) => {
    try {
        const typeDate = await findAllDates();
        const typeUnite = await findAllUnites();
        for (let k = 0; k < typeDate.length; k++) {
            if (typeDate[k].libelle === param.typeDate) {
                param.typeDate = "/api/type_dates/" + typeDate[k].id
            }
        }
        for (let l = 0; l < typeUnite.length; l++) {
            if (typeUnite[l].libelleUnite === param.typeUnite) {
                param.typeUnite = "/api/type_unites/" + typeUnite[l].id
            }
        }
        param.id = param.typeObjectifId;
        let dataToUpdate = param;

        const upDateTyopeobj = await axios
            .put(All.TYPE_OBJECTIFS_ID_API + dataToUpdate.id, dataToUpdate)
            .then(response => response.data);

        CreateObjectif.DateObjectifs(upDateTyopeobj);    
    } catch (err) {
        console.log(err);
    }
    return
}

const updateRowObjectif = async (param) => {
    try {

        if (param.isFloat !== undefined) {
            param.valeurFloat = Number(param.valeur, 10);
        } else if (param.isText !== undefined) {
            param.valeurTxt = param.valeur;
        } else if (param.isBoolean !== undefined) {
            param.valeurFloat = Number(param.valeur);
        } else {
            console.log("valeur indéfinie")
        }
        const updateObj = await axios
            .put(All.ALL_OBJECTIFS + "/" + param.id , param).then(response => response.data);
    } catch (err) {
        console.log(err);
    }
    return
}

const updateRowIndicateur = async (param) => {
    
    try {

        if (param.isFloat !== undefined) {
            param.valeurFloat = Number(param.valeur);
        } else if (param.isText !== undefined) {
            param.valeurTxt = param.valeur
        } else if (param.isBoolean !== undefined) {
            param.valeurFloat = Number(param.valeur);
        } else {
            console.log("valeur indéfinie")
        }
        await axios.put(All.ALL_INDICATEURS + "/" + param.id, param).then(response => response.data);
    } catch (err) {
        console.log(err);
    }
    return
}

const addTypeIndicateurResponsable = async (param,typeIndicId) => {
    //add relation between  typeIndication /responsable 
    const uriResponsable = "/api/responsables/" + param;
    try {
        await axios
        .put(All.TYPE_INDICATEUR_API + "/" + typeIndicId, {
            idResponsable: uriResponsable 
        })
        .then(response => response.data);
    }catch(err){
        console.log(err);
    }
}
//delete

function deleteRowTypeofIndicateur(param){
    return axios
        .delete(All.TYPE_INDICATEUR_API + "/" + param)
}

const deleteRowTypeofObjectif = async (param) => {
    const idTypeObjectif = param.typeObjectifId;
    const idTypeIndicateur = param.indicId;
    const data = {
        idResponsable : null
    };
    //delete and update relation typeIndicacteur
    try {
        await axios.delete(All.TYPE_OBJECTIFS_ID_API + idTypeObjectif)
        return axios.put(All.TYPE_INDICATEUR_API + "/" + idTypeIndicateur,data).then(response => response.data);
            
    } catch (err) {
        console.log(err)
    }
    
}

function deleteObjectif(param) {
    return axios
        .delete(All.ALL_OBJECTIFS + "/" + param)
}

function deleteIndicateur(param) {
    return axios
        .delete(All.ALL_INDICATEURS + "/" + param)
}

function deleteTypeFlag(param) {
    return axios
        .delete(All.ASSOC_FLAG_INDICATEUR + "/" + param)
}

const deleteFlagIndicateurValue = async (param) => {
    return axios
        .delete(All.ALL_FLAG_INDICATEUR_VALUES + "/" + param)
}

const removeTypeIndicateurResponsable = async (param) => {
    //remove relation between  typeIndication /responsable 
    try {
        await axios
        .put(All.TYPE_INDICATEUR_API + "/" + param, {
            "idResponsable":null 
        })
        .then(response => response.data);
    }catch(err){
        console.log(err);
    }
};
//sorting 

function sortTypeIndicateurs(param) {

    let idResponsable = param.selectResponsable !== "_all" ? param.selectResponsable : null;
    let idSousCategorie =  param.selectSousCategorie !== "_all" ? param.selectSousCategorie : null;
    let idCategorie =  param.selectCategorie !== "_all" ? param.selectCategorie : null;

    return axios
        .get(All.TYPE_INDICATEUR_API + "?" + "idResponsable.idResponsable=" + idResponsable + "&idSousCategorie.id=" + idSousCategorie + "&idSousCategorie.idCategorie.id=" + idCategorie )
        .then(response => response.data['hydra:member']);
}
function  sortIndicateurByTypeCategories(idTypeIndic){
    const data = {
        "idTypeIndicateur": idTypeIndic
    }
    return axios
        .post(All.SORTFLAG, data )
        .then(response => response.data);
}

function sortTypeObjectifs(param){
    
    let idSousCategorie =  param.selectSousCategorie !== "_all" ? param.selectSousCategorie : null;
    let idCategorie =  param.selectCategorie !== "_all" ? param.selectCategorie : null;
    
    return axios
        .get(All.TYPE_OBJECTIFS_API + "?" + "&idTypeIndicateur.idSousCategorie.id=" + idSousCategorie + "&idTypeIndicateur.idSousCategorie.idCategorie.id=" + idCategorie )
        .then(response => response.data['hydra:member']);

}

function sortCategorieSousCategorie(param) {
    let idSousCategorie =  param.selectSousCategorie !== "_all" ? param.selectSousCategorie : null;
    let idCategorie =  param.selectCategorie !== "_all" ? param.selectCategorie : null;
     
    if (idCategorie === null && idSousCategorie === null ) {
        return axios
        .get(All.TYPE_SOUS_CATEGORIE_API)
        .then(response => response.data['hydra:member']);
    }else {
        return axios
        .get(All.TYPE_SOUS_CATEGORIE_API + "?" + "id=" + idSousCategorie + "&idCategorie.id=" + idCategorie)
        .then(response => response.data['hydra:member']);

    }
}

function sortNewSousCategorie(param) {
    let idSousCategorie =  null;
    let idCategorie =  param.selectCategorie !== "_all" ? param : null;
     
    if (idCategorie === null && idSousCategorie === null ) {
        return axios
        .get(All.TYPE_SOUS_CATEGORIE_API)
        .then(response => response.data['hydra:member']);
    }else {
        return axios
        .get(All.TYPE_SOUS_CATEGORIE_API + "?" + "id=" + idSousCategorie + "&idCategorie.id=" + idCategorie)
        .then(response => response.data['hydra:member']);

    }
}

function sortAssocFlagIndicateurByIdIndic(param) {
    return axios
        .get(All.ASSOC_FLAG_INDICATEUR + "?" + "idtypeIndicateur=" + param )
        .then(response => response.data['hydra:member']);
}

function sortflagIndicValueByIdIndic(param) {
    return axios
        .get(All.ALL_FLAG_INDICATEUR_VALUES + "?" + "idIndicateur=" + param )
        .then(response => response.data['hydra:member']);
}

const duplicateAssocFlag =  async(initailIndicId,idIndicateur,idTypeIndicateur) => {
    const uriIndicateur = "/api/indicateurs/" + idIndicateur; 
    const parameters = {
        initailIndicId : initailIndicId,
        idIndicateur : idIndicateur ,
        idTypeIndicateur : idTypeIndicateur
    };
    let idToString;
    let flagTab = [];
    
    try {

        const assocFlagByIndic = await sortAssocFlagIndicateurByIdIndic(idTypeIndicateur);
        const responseSortedFlag = await axios
        .post(All.SORTASSOC,parameters)
        .then(response => response.data);
        
        assocFlagByIndic.forEach(element => {
            responseSortedFlag.forEach(sortFlag => {
                idToString = sortFlag.idtype_flag.toString();
                if(element.idtypeFlag.idtypeFlag == idToString){
                    flagTab.push({
                        idIndicateur: uriIndicateur,
                        idassocFlagIndicateur:  element.idassocFlagIndicateur,
                        valeurFlag: sortFlag.valeur_flag
                    })
                };
            })
        });

        for (const flag of flagTab) {
            axios
            .post(All.ALL_FLAG_INDICATEUR_VALUES, flag)
            .then(response => response.data);
        }

    } catch (error) {
        console.log(error);
    }

}


export default {
    findAllIndicateurs,
    findAllObjectifs,
    findAllTypeObjectifs,
    findAllDates,
    findAllUnites,
    findAllCategorie,
    findAllResponsable,
    findAllSousCategorie,
    findAllTypeIndicateurs,
    findAllTypeFlagSelect,
    findAllFlagIndicateurValues,
    findCategorieById,
    findObjectifbyId,
    findTypeIndicateurById,
    findTypeObjectifById,
    createIndicateurs,
    createOneIndicateur,
    createTypeObjectif,
    createTypeIndicateur,
    createObjectifs,
    createOneObjectif,
    createTypeFlag,
    createTypeFlagIndicValue,
    updateRowTypeIndicateur,
    updateRowTypeObjectif,
    updateRowObjectif,
    updateRowIndicateur,
    addTypeIndicateurResponsable,
    deleteRowTypeofIndicateur,
    deleteRowTypeofObjectif,
    deleteTypeFlag,
    deleteObjectif,
    deleteIndicateur,
    deleteFlagIndicateurValue,
    removeTypeIndicateurResponsable,
    sortTypeIndicateurs,
    sortIndicateurByTypeCategories,
    sortTypeObjectifs,
    sortCategorieSousCategorie,
    sortAssocFlagIndicateurByIdIndic,
    sortflagIndicValueByIdIndic,
    sortNewSousCategorie,
    duplicateAssocFlag,
}