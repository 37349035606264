import React, { useState, useEffect } from 'react';
//material-ui
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { TextField } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Save';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
//autocomplete
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
//query
import serviceApi from '../services/ServiceApi';



//style
const useStyles = makeStyles(theme => ({
    formControl: {
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        minWidth:"100%"
    },
    container: {
/*         marginLeft: "1vw",
        marginRight: "1vw",
        padding:"10px", */
    },
    margin: {
        margin: theme.spacing(1),
    },
}));


const ModalIndicateurs = ({ ShowIndicateurField,IDtypeIndicateur}) => {
    
    //styling
    const classes = useStyles();   


    const [flagIndicateurValuesList, setFlagIndicateurValuesList] = useState([]);
    useEffect(() => {
        const fetchFlagIndicateurValues = async () => {
            try { 
                const flagIndicValues = await serviceApi.findAllFlagIndicateurValues();
                let list = [];
                flagIndicValues.forEach(element => {
                    list.push({
                        value: element.idflagIndicateurValue,
                        name: element.valeurFlag,
                        id: element.idflagIndicateurValue
                    });
                });
                setFlagIndicateurValuesList(list);
            }catch(err) { 
                console.log(err) 
            }
        }
        fetchFlagIndicateurValues();
    },[])
    //modal
    const [libelleFlag, setLibelleFlag] = useState([]);
    const [refreshLibelle, setRefreshLibelle] = useState(false);
    const [typeFlagSelect, setTypeFlagSelect] = useState([]); 
    const [displayForm, setDisplayForm] = useState("initial");
    useEffect(() => {
        const fetchAssocFlagIndicateur = async (param) => {
            try {
                const dataAssocFlagIndicateur = await serviceApi.sortAssocFlagIndicateurByIdIndic(param);
                //console.log("dataAssocFlagIndicateur",dataAssocFlagIndicateur)
                let AssocFlagIndicateurListB = [];
                let AssocFlagIndicateurListTypeflag = [];
                dataAssocFlagIndicateur.forEach(element =>{
                    AssocFlagIndicateurListTypeflag.push({
                        value: element.idtypeFlag.libelle.toUpperCase(),
                        id : element.idtypeFlag.idtypeFlag,
                        key:element.idtypeFlag.libelle + element.idtypeFlag.idtypeFlag,
                        idAssocFlagIndicateur: element.idassocFlagIndicateur,
                    })
                });
                //query flag indicateur value by id indicateur ShowIndicateurField.id
                const dataFlagIndicateurValue = await serviceApi.sortflagIndicValueByIdIndic(ShowIndicateurField.id);
                //const dataAllFlagIndicateurValue = await serviceApi.findAllFlagIndicateurValues();
                //console.log("dataFlagIndicateurValue",dataFlagIndicateurValue);
                //
                AssocFlagIndicateurListTypeflag.forEach(element => {
                    dataFlagIndicateurValue.forEach(val => {

                        if (element.idAssocFlagIndicateur === val.idassocFlagIndicateur ) {
                            AssocFlagIndicateurListB.push({
                                value : element.value,
                                idtypeFlag : element.id,
                                key : element.key,
                                idAssocFlagIndicateur: element.idAssocFlagIndicateur,        
                                idIndicateur: val.idIndicateur.id,
                                idflagIndicateurValue : val.idflagIndicateurValue,
                                valeurFlag: val.valeurFlag,
                                
                            })
                        }
                    });                    
                })

                setLibelleFlag(AssocFlagIndicateurListB);
                // set the select 
                const dataTypeFlagSelect = await serviceApi.sortAssocFlagIndicateurByIdIndic(param)
                let TypeFlagSelectList = [];
                let TypeFlagSelectListB = [];
                dataTypeFlagSelect.forEach(element => {
                    TypeFlagSelectList.push({
                        value: element.idtypeFlag.idtypeFlag,
                        name: element.idtypeFlag.libelle,
                        id: element.idtypeFlag.idtypeFlag,
                        key: "flag"+element.idtypeFlag.idtypeFlag
                    });
                });

                AssocFlagIndicateurListB.forEach(element => {
                    TypeFlagSelectList.forEach(val => {
                        if (element.idtypeFlag !== val.id ){
                            TypeFlagSelectListB.push({
                                value: val.value,
                                name: val.name,
                                id: val.id,
                                key: "flag"+val.id+val.name
                            })
                        }
                    });
                });
                AssocFlagIndicateurListB.length > 0 ? setTypeFlagSelect(TypeFlagSelectListB) : setTypeFlagSelect(TypeFlagSelectList);
                AssocFlagIndicateurListB.length > 0 && AssocFlagIndicateurListB.length === TypeFlagSelectList.length ?  setDisplayForm("none") : setDisplayForm("initial") ;
                setRefreshLibelle(false);

            }catch(error){
                console.log(error)
            }

        };

            fetchAssocFlagIndicateur(IDtypeIndicateur);
            
    },[IDtypeIndicateur,refreshLibelle]);
    
    //indicateur
    const [indicateur, setIndicateur] = useState([]);
    useEffect(() => {
        const fetchTitles = async (param) => {
            try{
                const dataIndicateur = await serviceApi.findTypeIndicateurById(param)
                setIndicateur(dataIndicateur);
            }catch(err){
                console.log(err)
            }
        }
            fetchTitles(IDtypeIndicateur);
    },[IDtypeIndicateur])


    //autocomplete
    const filter = createFilterOptions();
    const [value, setValue] = React.useState(null);
    //on change flag
    const [flagValue, setFlagValue] = useState('');
    const handleChangeFlagIndicValue = async (event) => {
        event.preventDefault();        
        const typeIndicId = ShowIndicateurField.typeIndicateurId;
        const indicId = ShowIndicateurField.id;
        console.log("flagValue",flagValue);
        console.log("Value",value);
        try {
            await serviceApi.createTypeFlagIndicValue(flagValue,typeIndicId,value,indicId);
            setRefreshLibelle(true);
        }catch(error){
            console.log(error);
        }
    }
    //delete
    const deleteFlag = async (param) => {
        console.log("deleteType",param);
        try {
            await serviceApi.deleteFlagIndicateurValue(param.idflagIndicateurValue);
            setRefreshLibelle(true);
        }catch(error){
            console.log(error)
        }
    }

    //render
    return (
        <div>
                    <Box
                        className={classes.container}
                    >
 
                        {libelleFlag.map((flag,i) => (
                            <React.Fragment key={i}>
                                <Box display="inline"  >
                                        <Typography
                                            display="inline"
                                            variant="inherit" 
                                            component="p"
                                            gutterBottom
                                            color='primary'
                                        >        
                                            {flag.value} :
                                        </Typography  >    
                                </Box>
                                <Box >
                                        <Typography
                                            //display="inline"
                                            variant="inherit" 
                                            component="p"
                                            gutterBottom
                                        >        
                                            - {flag.valeurFlag}
                                        </Typography  >    
                                </Box>
                            </React.Fragment>
                        ))}
                    </Box>
        </div>
    )
}

export default ModalIndicateurs;