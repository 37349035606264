import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, {MTableToolbar} from 'material-table';
import jwtDecode from "jwt-decode";
import {SENDEMAIL} from "../config.js";
import axios from "axios";

import UserAPI from '../services/UserApi';
//icons
import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import RemoveIcon from '@material-ui/icons/Remove';
import { BsFillInfoCircleFill } from "react-icons/bs";
import { FcOk } from "react-icons/fc";
//mui
import { FormControl,
         FormHelperText,
         Select,
         MenuItem,
        } from '@material-ui/core';
        
//error message
import ErrorMessage from '../components/errorMessage'
//password generator
import passwordGenerator from '../services/passwordGenerator'
//styling
import * as styling from '../services/styling'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '15vh',
        marginLeft: '30px',
        width: 'fit-content',
        //remplace with breakpoint
    },
    table: {
        marginLeft: '30px',
        marginRight: '30px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    inputForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    title: {
        backgroundColor: '#1c60A3',
        color: '#FFF',
        height: "5vh",
        textTransform: "uppercase",
    },
    toolbar:{
        backgroundColor: styling.backGroundHeader,
        
    },
    page: {
        marginLeft: '30px',
        marginRight: '30px',
    },
}));
        
const Account = () => {
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [refreshTab, setRefreshTab] = useState(false);

    /*const checkEmail = (param) => {
        if(param.indexOf('@') !== -1 && param.indexOf('.') !== -1){
            return true;
        } else {
            return false;
        }
    }*/
    const [roleSuperAdmin, setRoleSuperAdmin] = useState(false);
    useEffect(() => {
        const getDecodedToken = () => {
            const tokenBrut = window.localStorage.getItem("authToken");
            if(tokenBrut){
                const decodedToken = jwtDecode(tokenBrut);
                
                if(decodedToken){
                    decodedToken.roles[0] === "SUPER_ADMIN" ? setRoleSuperAdmin(true) : setRoleSuperAdmin(false);         
                }
            }
        }
        getDecodedToken();
    }, []);


    const [nameError, setNameError] = React.useState({
        error: false,
        label: "",
        helperText: "",
        validateInput: false,
    });

    const [state, setState] = useState({
        columns: [
            {
                title: 'Email', field: 'email',

                headerStyle: {
                    textTransform: "uppercase",
                    textAlign: 'left',
                },
            },
            { title: 'Rôle', field: 'roles[0]',
                headerStyle: {
                    textTransform: "uppercase",
                    textAlign: 'left',
                },
                render: rowData => {
                    switch(rowData.roles[0]){
                        case 'ROLE_USER':
                            return 'UTILISATEUR';
                            break;
                        case 'USER':
                            return 'UTILISATEUR';
                            break;
                        case 'SUPER_USER':
                            return 'SUPER_UTILISATEUR';
                            break;                            
                        case 'ADMIN':
                            if(role === "SUPER_ADMIN" ){
                                return 'ADMINISTRATEUR';                               
                            }else{
                                return
                            }
                            break;
                        case 'SUPER_ADMIN':
                            if(role === "SUPER_ADMIN" ){
                                return 'SUPER_ADMINISTRATEUR';                               
                            }else{
                                return
                            }                            
                            break;
                    }
                },
                editComponent: (props) => (
                    <>
                        <FormControl required >
                            <Select
                                labelId="select-roleUtilisateur-required-label"
                                id="select-roleUtilisateur-required"
                                value={props.value ? props.value : ""}
                                defaultValue={props.value}
                                autoWidth
                                onChange={(e) => {
                                    props.onChange(e.target.value);
                                }}
                            >
                                <MenuItem value="USER" name="utilisateur">UTILISATEUR</MenuItem>
                                <MenuItem value="SUPER_USER" name="super_admin">SUPER_UTILISATEUR</MenuItem>
                                {role === "SUPER_ADMIN" ? <MenuItem value="ADMIN" name="admin">ADMINISTRATEUR</MenuItem>: null }
                                {role === "SUPER_ADMIN" ? <MenuItem value="SUPER_ADMIN" name="super_admin">SUPER_ADMINISTRATEUR</MenuItem>: null }
                            </Select>   
                            <FormHelperText>Requis</FormHelperText>
                        </FormControl>
                    </>
                )
            },
            { title: 'Indicateur(s) attribué(s)', field: 'assocUserTypeIndicateurs.idtypeindicateur.libelle', editable: 'never',
                headerStyle: {
                    textTransform: "uppercase",
                    textAlign: 'left',
                },
                render: rowData => { 
                    const data = rowData ? rowData.assocUserTypeIndicateurs : "";
                    let count = 0;
                    if(data){
                        data.forEach((obj) => {
                            if(obj.lecture === true){
                                count++
                            }     
                        })
                    }

                    return (
                        <div>{count}</div>
                    )
                },
            },
            { title: 'Statut de validation', field: 'resetToken', editable: 'never', 
            /* cellStyle: rowData => rowData ? {backgroundColor:'orange'} : {backgroundColor:'green'}, */
                headerStyle:{
                    textAlign: 'center',
                    textTransform: "uppercase",
                },
                cellStyle:{
                    textAlign: 'center'
                },
                render: rowData => {
                    let val;
                   
                    if (rowData === undefined ){
                        val = <BsFillInfoCircleFill size={20} style={{fill: 'orange'}}/>                        
                    }else{
                        if (rowData.resetToken){
                            val = <BsFillInfoCircleFill size={20} style={{fill: 'orange'}}/>
                        }else{
                            val = <FcOk size={20} style={{fill: 'green'}}/>
                        }
                    }
                    return val;
                }
            },
        ],
      });
      let role = "";
    useEffect(() => {
        let filtreredUsers = [];
        
        const fetchUsers = async () => {
            try {
                const tokenBrut = window.localStorage.getItem("authToken");
                if(tokenBrut){
                    const decodedToken = jwtDecode(tokenBrut);
                    
                    if(decodedToken){
                        role = decodedToken.roles[0];
                    }
                }
                const responseUsers = await UserAPI.findAllUsers();
                    if(role === "SUPER_ADMIN"){
                        setUsers(responseUsers)
                    }else{
                        responseUsers.forEach(user =>{
                            if (user.roles[0] === 'USER'){
                                filtreredUsers.push(user);
                            }
                            if (user.roles[0] === 'SUPER_USER'){
                                filtreredUsers.push(user);
                            }
                        });
                        setUsers(filtreredUsers);
                    }

            } catch(error){
                console.log(error);
            }
        }

        fetchUsers();
    }, [refreshTab]);

    const handleRowClick = (event, rowData) => {
        event.preventDefault();
    }

    const addNewRow = async (param) => {
        if(param !== null){
            try {
                param.password = passwordGenerator.getPassword();
                await UserAPI.postUser(param);
                await axios.post(SENDEMAIL, {email: param.email});
                setRefreshTab(!refreshTab);
            } catch (error) {
                console.log(error);
                ErrorMessage.ErrorMessage();

            }
        } 
    }

    const updateRow = async (param) => {
        let tabOfUriTypeIndicateur = [];
        let tab = param.assocUserTypeIndicateurs;

        tab.forEach((item) => {
            tabOfUriTypeIndicateur.push("/api/assoc_user_type_indicateurs/" + item.id);
        })

        param.assocUserTypeIndicateurs = tabOfUriTypeIndicateur;

        try {
            await UserAPI.changeOneUser(param.id, param);
            setRefreshTab(!refreshTab);
        } catch (error) {
            console.log(error);
        }
    }

    const deleteRow = async (param) => {
        try {
            await UserAPI.deleteUser(param);
            setRefreshTab(!refreshTab);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className={classes.page}>
            <div> 
                <p>Afin de pouvoir ajouter ou modifier les indicateurs associés aux utilisateurs, vous devez vous rendre sur l'onglet "Gestion des droits"</p>
            </div>
            <MaterialTable
                title={"Création des utilisateurs".toUpperCase()}
                columns={state.columns}
                data={users}
                onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                components={{
                    Toolbar: props => (
                    <div style={{ color:styling.colorTextHeader }}>
                        <MTableToolbar {...props} classes={{ root: classes.toolbar }} />
                    </div>
                    ),
                }}
                options={{
                    rowStyle: {
                        backgroundColor: styling.tableRowBackgroundColor,
                    },
                    headerStyle: {
                        backgroundColor: styling.tableHeaderBackgroundColor,
                        color: styling.tableHeaderColor
                    },
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    tableLayout: "fixed", //columns dimensions
                    //actionsColumnIndex: -1, //action column o right side
                    addRowPosition: 'first' //add row on top
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: "{from} à {to} sur {count}",
                        labelRowsSelect: "résultats par page",
                        labelRowsPerPage: "résultats par page",
                        firstAriaLabel: "Première page",
                        firstTooltip: "Première page",
                        previousAriaLabel: "Page précédente",
                        previousTooltip: "Page précédente",
                        nextAriaLabel: "Page suivante",
                        nextTooltip: "Page suivante",
                        lastAriaLabel: "Dernière page",
                        lastTooltip: "Dernière page"
                    },
                    toolbar: {
                        addRemoveColumns: "Ajouter ou supprimer des colonnes",
                        nRowsSelected: "{0} ligne(s) sélectionnée(s)",
                        showColumnsTitle: "Afficher les colonnes",
                        showColumnsAriaLabel: "Afficher les colonnes",
                        searchTooltip: "Recherche",
                        searchPlaceholder: "Recherche"
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage:
                            "Désolé, aucun enregistrement correspondant n'a été trouvé",
                        addTooltip: "Ajouter une ligne",
                        deleteTooltip: "Supprimer l'utilisateur",
                        editTooltip: "Modifier l'utilisateur",
                        filterRow: {
                            filterTooltip: "Filtrer"
                        },
                        editRow: {
                            deleteText: "Êtes-vous sûr de vouloir supprimer cet élément ? Vous allez perdre TOUTES les données associées",
                            cancelTooltip: "Annuler",
                            saveTooltip: "Sauvegarder"
                        }
                    },
                }}
                style = {{
                    color: styling.tableTextColor,
                }}
                icons={{
                    Add: props => <AddCircleRounded {...props} style={{fill: styling.tabButtonAddRow}} />,
                    Edit: props => <Edit  {...props} style={{fill: "#175Be5"}} />,
                    Delete: props => <DeleteForeverIcon   {...props} style={{fill: "#d50000"}} />,
                    Check: props => <Check   {...props} style={{fill: "#2e7d32"}} />,
                    Clear: props => <Clear    {...props} style={{fill: "#d50000"}} />,
                    ThirdStateCheck: props => <RemoveIcon  {...props} style={{fill: "#d50000"}} />,
                }}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                        setTimeout(() => {
                            //console.log(newData);
                            addNewRow(newData);
                            resolve();
                        }, 600);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataUpdate = [...users]; // data déja présente dans le tableau avant édition
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData; // remplacement des nouvelles données 
                            //setEntries([...dataUpdate]);
                            updateRow(newData);
                            resolve();
                        }, 600);
                    }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                const dataDelete = [...users];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                //setEntries([...dataDelete]);
                                deleteRow(oldData.id);
                                resolve();
                            }, 600);
                    }),
                }}
            />
            </div>
        </>
    );
}

export default Account;