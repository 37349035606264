import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//image
import imageCo from '../images/pbi2.png';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      marginTop:'30px',
      width: '100vw',
      height: '80vh',
      textAlign: 'center'
    },
    section : {
        margin : '0 auto',
        paddingTop : 150
     
    },
    image : {
        height: 300,
        width: 300,
        "&:hover" : {
            opacity: 0.75
        }
    }
}));

const TableauDeBord = () => {
    const classes = useStyles();
    const urlPbi = "//powerbi.microsoft.com/fr-fr/";

    return (
        <div className={classes.root}>
            <section className={classes.section}>
                <a href={urlPbi} target="_blank">
                    <img className={classes.image} src={ imageCo} title="rapport PBI" alt="rapport PBI"  ></img>
                </a>
            </section>
        </div>
    )
}

export default TableauDeBord;