import React, { useState, useEffect } from 'react';
//material-ui
import { makeStyles } from '@material-ui/core/styles';
//material-table
import MaterialTable, {MTableToolbar} from 'material-table';
//dates picker
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import frLocale from "date-fns/locale/fr";
//select
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
//input text field
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
//checkbox 
import Checkbox from '@material-ui/core/Checkbox';
//icons
import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
//query
import serviceApi from '../services/ServiceApi';
//styling
import * as styling from '../services/styling';

const useStyles = makeStyles(theme => ({

    table: {
        padding: '30px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    toolbar:{
        backgroundColor: styling.backGroundHeader,
        
    },
    selectFormControl: {
        margin: theme.spacing(1),
        minWidth: "100%",

    },
}));

const ObjectifTable = (param) => {
    
    //styling
    const classes = useStyles();

    //isLoading
    const [chargement,setChargement] = useState({
        isLoading : false
    });

   //typeObjectif ID
   const typeObjectID = param.ShowSecondTable.typeObjectifId;
   const [typeUnite, setTypeUnite] = useState('');
    
    //input text error
    const [nameError, setNameError] = React.useState({
        error: false,
        label: "",
        helperText: "",
        validateInput: false,
    });
    
    //const entries = param.ShowSecondTable;
    const [entries, setEntries] = useState([]);
    
    //table column definition\\
    const [columnDefinition, setColumnDefinition] = useState({
        columns: [
            {
                title: "Date de mise à jour", field: "date", type: "date",width: "20%", 
                headerStyle: {
                    textTransform: "uppercase",
                },
                editComponent: (props) => (
                    <>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    value={props.value ? props.value : ""}
                                    onChange={(e) => {
                                        props.onChange(e);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </>
                )
            },
            {
                title: "Unité", field: 'typeUnite', editable: "never",width: "10%",
                headerStyle: {
                    textTransform: "uppercase",
                },
            },
            {
                title: "Valeur", field: 'valeur', 
                headerStyle: {
                    textTransform: "uppercase",
                },
                editComponent: (props) => {
                    const parent = dataParent.get("responseTypeObjectif");
                    let ischecked;
                    let value;
                    let type;
                    parent.typeUnite.isFloat !== undefined ? type = "number" : type = "string";
                    if (parent.typeUnite.isBoolean !== undefined) {
                        props.rowData.valeur === 1 ? ischecked = true : ischecked = false;
                        return <Checkbox
                            checked={ischecked ? true : false}
                            onChange={(e) => {
                                e.target.checked === true ? value = 1 : value = 0;
                                props.onChange(value);
                            }}
                        />
                    } else {
                        return     (<form className={classes.inputForm} noValidate autoComplete="off">
                                        <TextField
                                            className={classes.formControl}
                                            required
                                            type={type}
                                            size="small"
                                            variant="filled"
                                            error={
                                                !props.value &&
                                                nameError.validateInput &&
                                                props.rowData.submitted
                                                    ? nameError.error
                                                    : false
                                            }
                                            helperText={
                                                !props.value &&
                                                nameError.validateInput &&
                                                props.rowData.submitted
                                                    ? nameError.helperText
                                                    : ""
                                            }
                                            value={props.value ? props.value : ""}
                                            onChange={(e) => {
                                                if (nameError.validateInput) {
                                                    setNameError({
                                                        ...nameError,
                                                        validateInput: false,
                                                    });
                                                }
                                                props.onChange(e.target.value);
                                            }}
                                        />
                                        <FormHelperText>Requis</FormHelperText>
                                    </form>)
                    } 
                },
                render: rowData => {
                    if (rowData.isBoolean !== undefined) {
                        if (rowData.valeur === 1) {
                            return <div>Oui</div>
                        } else if (rowData.valeur === 0){
                            return  <div>Non</div>
                        }else{
                            return <div>-</div>
                        }
                    } else {
                        return rowData.valeur
                    }
                }                
            },
            {
                title: "Commentaire à afficher sur la DataViz", field: 'commentaire', 
                editComponent:(props) => (
                    <TextField
                    id="commentaire-multiline-static"
                    multiline
                    label="commentaire"
                    className={classes.selectFormControl}
                    rows={4}
                    defaultValue={props.value}
                    variant="outlined"
                    onChange={(e)=>{
                        props.onChange(e.target.value);
                    }}
                  />
                ),
                headerStyle: {
                    textTransform: "uppercase",
                },
            },
            {
                title: "Commentaire caché (non reporté en dataviz)", field: 'commentaireNonpbi',
                editComponent:(props) => (
                    <TextField
                    id="commentaireNonpbi-multiline-static"
                    multiline
                    label="commentaire Non pbi"
                    className={classes.selectFormControl}
                    rows={4}
                    defaultValue={props.value}
                    variant="outlined"
                    onChange={(e)=>{
                        props.onChange(e.target.value);
                    }}
                  />
                ),
                headerStyle: {
                    textTransform: "uppercase",
                },
            },
        ],
    });
    //reload
    const [refreshTab, setRefreshTab] = useState(false); 
    const [canAdd, setCanAdd] = useState(false);
    // type objectif
    const [dataParent, setDataParent] = useState(new Map());
    useEffect(() => {
        const fetchsecondObj = async (param) => {            
            setChargement({
                isLoading:true
            });
            const dataObj = await serviceApi.findAllObjectifs();
            const responseTypeObjectif = await serviceApi.findTypeObjectifById(param.typeObjectifId);
            setDataParent(prevState => prevState.set("responseTypeObjectif", responseTypeObjectif));

            let objectifsTab = [];

            dataObj.forEach(element => {                
                if (element.idTypeObjectif.id === param.typeObjectifId ){
                    objectifsTab.push({
                        id: element.id,
                        date: element.date,
                        typeObjectifId : element.idTypeObjectif.id,
                        valeur: element.valeurTxt ? element.valeurTxt : element.valeurFloat,
                        isFloat : element.idTypeObjectif.typeUnite.isFloat,
                        isText : element.idTypeObjectif.typeUnite.isText,
                        isBoolean : element.idTypeObjectif.typeUnite.isBoolean,
                        typeUnite: element.idTypeObjectif.typeUnite.libelleUnite ,
                        commentaire: element.commentaire,
                        commentaireNonpbi: element.commentaireNonpbi, 
                        indicId : element.idTypeObjectif.idTypeIndicateur.id,       
                        access: {
                            role: param.access.role,
                            lecture: true,
                            ecriture: true,
                            suppression: true
                        },
                        accessUser : {
                            role: param.access.role,
                            lecture: (param.access.role === "USER") ? param.accessUser.lecture : param.access.lecture,
                            ecriture: (param.access.role === "USER") ? param.accessUser.ecriture : true,
                            suppression: (param.access.role === "USER") ? param.accessUser.ecriture : true
                        }         
                    });
                    setCanAdd((param.access.role === "USER") ? param.access.ecriture : true)
                }
            });
            //if empty array, set default write permissions 
            if(param.access && objectifsTab.length === 0) {
                setCanAdd((param.access.role === "USER") ? param.accessUser.ecriture : true )            
            }
            //animation
            setChargement({
                isLoading:false
            });  
            //set Unité
            setTypeUnite(param.typeUnite ? param.typeUnite : "");
            //set table entries 
            setEntries(objectifsTab);
        };
        
        fetchsecondObj(param.ShowSecondTable);

    },[param.ShowSecondTable,refreshTab]);

    // Row add , edit or  delete
    const addNewRow = async (param) => {

        try {
            await serviceApi.createOneObjectif(param,typeObjectID,typeUnite);
            setRefreshTab(true)
        } catch (error) {
            console.log(error);
        }
    }
    const updateRow = async (param) => {
        try{
            await serviceApi.updateRowObjectif(param);
            setRefreshTab(true)
        }catch(error){
            console.log(error);
        }
    }
    const deleteRow = async (param) => {
        try{
            await serviceApi.deleteObjectif(param);

        }catch (error) {
            console.log(error)
        }
    }
        
    //duplicate row
    const duplicateRow = async (param) => {
        const data = {
            commentaire: param.commentaire ? param.commentaire : null,
            commentaireNonpbi: param.commentaireNonpbi ? param.commentaireNonpbi : null,
            date: param.date,
            isBoolean: param.isBoolean ? param.isBoolean : null,    
            isFloat: param.isFloat ? param.isFloat : null,
            isText: param.isText ?  param.isText : null,
            typeObjectifId: param.typeObjectifId,
            typeUnite: param.typeUnite,
            valeur: param.valeur ? param.valeur : null
        };
        await serviceApi.createOneObjectif(data,param.typeObjectifId,param.typeUnite);
        setRefreshTab(!refreshTab) ;
    }

    return (
        <>
        <div className={classes.table}>   
            <MaterialTable
                title = {'RENSEIGNER UN OBJECTIF'}
                columns={columnDefinition.columns}
                data={entries}
                isLoading = {chargement.isLoading}
                components={{
                    Toolbar: props => (
                      <div style={{  color:styling.colorTextHeader }}>
                        <MTableToolbar {...props} classes={{ root: classes.toolbar }} />
                      </div>
                    ),
                  }}
                options={{
                    rowStyle: {
                        backgroundColor: styling.tableRowBackgroundColor,
                    },
                    headerStyle: {
                        backgroundColor: styling.tableHeaderBackgroundColor,
                        color: styling.tableHeaderColor
                    },
                    pageSize: 12,
                    pageSizeOptions: [12, 24, 48,{ label: 'Tout', value: entries.length }],
                    //actionsColumnIndex: -1, //action column o right side
                    addRowPosition: 'first', //add row on top
                    padding:'dense'
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: "{from} à {to} sur {count}",
                        labelRowsSelect: "résultats par page",
                        labelRowsPerPage: "résultats par page",
                        firstAriaLabel: "Première page",
                        firstTooltip: "Première page",
                        previousAriaLabel: "Page précédente",
                        previousTooltip: "Page précédente",
                        nextAriaLabel: "Page suivante",
                        nextTooltip: "Page suivante",
                        lastAriaLabel: "Dernière page",
                        lastTooltip: "Dernière page"
                    },
                    toolbar: {
                        addRemoveColumns: "Ajouter ou supprimer des colonnes",
                        nRowsSelected: "{0} ligne(s) sélectionnée(s)",
                        showColumnsTitle: "Afficher les colonnes",
                        showColumnsAriaLabel: "Afficher les colonnes",
                        exportTitle: "Exporter",
                        exportAriaLabel: "Exporter",
                        exportName: "Exporter en CSV",
                        searchTooltip: "Recherche",
                        searchPlaceholder: "Recherche"
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage:
                            "Désolé, aucun enregistrement correspondant n'a été trouvé",
                        addTooltip: "Ajouter une ligne",
                        deleteTooltip: "Supprimer",
                        editTooltip: "Modifier",
                        filterRow: {
                            filterTooltip: "Filtrer"
                        },
                        editRow: {
                            deleteText: "Êtes-vous sûr de vouloir supprimer cet élément ? Vous allez perdre TOUTES les données associées",
                            cancelTooltip: "Annuler",
                            saveTooltip: "Sauvegarder"
                        }
                    },
                }}
                style = {{
                    color: styling.tableTextColor,
                }}
                icons={{
                    Add: props => <AddCircleRounded {...props} style={{fill: styling.tabButtonAddRow }} />,
                    Edit: props => <Edit  {...props} style={{fill: "#ff9800"}} />,
                    Delete: props => <DeleteForeverIcon   {...props} style={{fill: "#d50000"}} />,
                    Check: props => <Check   {...props} style={{fill: "#2e7d32"}} />,
                    Clear: props => <Clear    {...props} style={{fill: "#d50000"}} />,
                }}
                actions={[
                    rowData => ({
                        icon: () => <HiOutlineDocumentDuplicate style={{color: "#42B348"}} />,
                        tooltip: 'Dupliquer l\'objectif',
                        onClick: (event, rowData) => {
                            duplicateRow(rowData);
                        }, 
                        //display buttons according to role
                        disabled: !rowData.accessUser.ecriture,
                        hidden: !rowData.accessUser.ecriture                        
                    }),
                ]}                 
                editable={{
                    //isEditable: rowData => roleIndicateur.canEdit(rowData), 
                    isEditHidden: rowData => !rowData.accessUser.ecriture ,
                    //isDeletable: rowData => rowData.sousCategorie === 'Communication',
                    isDeleteHidden: rowData => !rowData.accessUser.suppression , 

                    onRowAdd: canAdd ? (newData) =>
                        new Promise((resolve, reject) => {
                                newData.submitted = true;
                                console.log("newData",newData)
                                if (!newData.date) {
                                    setNameError({
                                        error: true,
                                        label: "champs requis",
                                        helperText: "erreur",
                                        validateInput: true,
                                    });
                                    reject();
                                    return;
                                }
                                addNewRow(newData);
                                resolve();

                        }): null,
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                newData.submitted = true;
                                if (!newData.date) {
                                    setNameError({
                                        error: true,
                                        label: "champs requis",
                                        helperText: "erreur",
                                        validateInput: true,
                                    });
                                    reject();
                                    return;
                                }
                                
                                const dataUpdate = [...entries];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setEntries([...dataUpdate]); 
                                updateRow(newData);
                                resolve();
                            }, 1000);
                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                                const dataDelete = [...entries];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setEntries([...dataDelete]);
                                deleteRow(oldData.id);
                                resolve();

                        }),
                }}                
            />
        </div>        
        </>
    )
}



export default ObjectifTable;
